
function Platform()
{
    /***** Object API *****/

    this.makeAjaxCall = function (urldata,resulttype) {
        var result = this.getObjectCache(urldata);
        if(typeof result == 'undefined' || result == ''){
            var THIS = this;
            var xhr_field = {
                withCredentials: true
            };
            if (urldata.indexOf('.json') > -1) {
                xhr_field = false;
            }

            // adding api_version to url
            if(urldata.indexOf('?') > -1){
                urldata += '&api_version=' + API_VERSION;
            }else{
                urldata += '?api_version=' + API_VERSION;
            }

			if(typeof APP_TYPE != "undefined" && APP_TYPE == "ios") {
				if (urldata.indexOf('api/login/redirect') > -1) {
			        urldata = Platform.getAuthenticatedURL(urldata, 'GET', 'cloud');
			    } else if (urldata.indexOf('api/login/user') > -1) {
			        urldata = Platform.getAuthenticatedURL(urldata, 'POST', 'cloud');
			    } else {
		            var deviceId = Platform.getDeviceMacAddress();
		            if(deviceId != "")
		                urldata += "&deviceid=" + deviceId;
				}
			}
            $.ajax({
                type: "GET",
                url: urldata,
                async: false,
                xhrFields: xhr_field,
                timeout: 40000,
                crossDomain: true,
                success: function (data) {
                    // Run the code here that needs
                    // to access the data returned
                    result = data;
                    THIS.addorUpdateObjectCache(urldata,result);
                },
                error: function(xhr, status, error) {
                  status = xhr.status;
                  var error_message = error.message ? error.message : error;
                  console.log("url = " + urldata + " Error = " + error_message);

                    if(status == "500" || status == "502" || status == "503" || status == "504"){
                      Client.closeProgressBar();
                      alert(ERROR_MESSAGE);
                    } else if(status == "403"){
                        for(var i in LOGIN_RESTRICTED_URLS){
                            if(urldata.indexOf(LOGIN_RESTRICTED_URLS[i]) > 0){
                                onAccessDenied();
                            }
                        }
                    }
                }
            });
        }

        if(typeof resulttype == "undefined")
            return result;

        if(resulttype === "boolean" && result === "true")
            return true;
        else if(resulttype === "boolean" && result === "false")
           return false

        return result;
    };

     this.makeAjaxPostCall = function (urldata,content,resulttype,methodtype,ignorecontent) {
        if(OBJECT_SERVER_DOMAIN.includes("demo.tribyte.com")
        || (typeof IGNORE_CONTENT_IN_POST_CALLS != "undefined" && IGNORE_CONTENT_IN_POST_CALLS == true)){
            ignorecontent = true;
        }
        var result = "";
        var params = "";
        var status = "";

        if(typeof content == "undefined" || content == ""){
            result = this.getObjectCache(urldata);
        }

        if(typeof methodtype == 'undefined'|| methodtype == ""){
            methodtype = 'post';
        }
        if(!content || methodtype == "get") {
            methodtype = "get";
            content = false;
        } else if(ignorecontent){
            content = content;
        } else {
            content = "content=" + content;
        }

        var xhr_field = {
            withCredentials: true
        };
        if (urldata.indexOf('.json') > -1) {
            xhr_field = false;
        }

        // adding api_version to url
        if(urldata.indexOf('?') > -1){
            urldata += '&api_version=' + API_VERSION;
        }else{
            urldata += '?api_version=' + API_VERSION;
        }

		if(typeof APP_TYPE != "undefined" && APP_TYPE == "ios") {
			if (urldata.indexOf('api/login/redirect') > -1) {
		        urldata = Platform.getAuthenticatedURL(urldata, 'GET', 'cloud');
		    } else if (urldata.indexOf('api/login/user') > -1) {
		        urldata = Platform.getAuthenticatedURL(urldata, 'POST', 'cloud');
		    } else {
	            var deviceId = Platform.getDeviceMacAddress();
	            if(deviceId != "")
	                urldata += "&deviceid=" + deviceId;
			}
		}
        if(typeof result == "undefined" || result == ""){
            var THIS = this;
            $.ajax({
                type: methodtype,
                url: urldata,
                dataType: 'text',
                data: content,
                timeout: 40000,
                xhrFields: xhr_field,
                crossDomain: true,
                contentType: "application/x-www-form-urlencoded",
                async: false,
                success: function (data, textStatus, xhr) {
                    if(typeof data == "object"){
                        data = JSON.stringify(JSON.stringify(data));
                    }
                    else{
                        data = JSON.stringify($.trim(data));
                    }
                    result = '{"status":'+xhr.status+',"data":'+data+'}';
                    THIS.addorUpdateObjectCache(urldata,result);
                },
                error: function(xhr, status, error) {
                    status = xhr.status;
                    result = '{"status":'+status+'}';
                    var error_message = error.message ? error.message : error;
                    console.log("url = " + urldata + " Error = " + error_message);
                    if(status == "500" || status == "502" || status == "503" || status == "504"){
                      Client.closeProgressBar();
                      alert(ERROR_MESSAGE);
                    }else if(status == "403"){
                        for(var i in LOGIN_RESTRICTED_URLS){
                            if(urldata.indexOf(LOGIN_RESTRICTED_URLS[i]) > 0){
                                onAccessDenied();
                            }
                        }
                    }
                }
            });
        }

        if(typeof resulttype == "undefined" || resulttype == "")
            return result;

        return this.parseresult(result,resulttype);
    };

    this.makeGetAsyncCall = function (urldata, callback, resulttype) {
        var result = "";
        var xhr_field = {
            withCredentials: true
        };
        if (urldata.indexOf('.json') > -1) {
            xhr_field = false;
        }

        // adding api_version to url
        if(urldata.indexOf('?') > -1){
            urldata += '&api_version=' + API_VERSION;
        }else{
            urldata += '?api_version=' + API_VERSION;
        }

		if(typeof APP_TYPE != "undefined" && APP_TYPE == "ios") {
			if (urldata.indexOf('api/login/redirect') > -1) {
		        urldata = Platform.getAuthenticatedURL(urldata, 'GET', 'cloud');
		    } else if (urldata.indexOf('api/login/user') > -1) {
		        urldata = Platform.getAuthenticatedURL(urldata, 'POST', 'cloud');
		    } else {
	            var deviceId = Platform.getDeviceMacAddress();
	            if(deviceId != "")
	                urldata += "&deviceid=" + deviceId;
			}
		}
        $.ajax({
            type: "GET",
            url: urldata,
            async: true,
            xhrFields: xhr_field,
            crossDomain: true,
            success: function (data) {
                // Run the code here that needs
                // to access the data returned
                result = data;

                if(resulttype === "boolean" && result === "true")
                    result = true;
                else if(resulttype === "boolean" && result === "false")
                   result = false

                return callback(result);
            },
            error: function(xhr, status, error) {
                status = xhr.status;
                var error_message = error.message ? error.message : error;
                console.log("url = " + urldata + " Error = " + error_message);

                if(status == "500" || status == "502" || status == "503" || status == "504"){
                  Client.closeProgressBar();
                  alert(ERROR_MESSAGE);
                } else if(status == "403"){
                    for(var i in LOGIN_RESTRICTED_URLS){
                        if(urldata.indexOf(LOGIN_RESTRICTED_URLS[i]) > 0){
                            onAccessDenied();
                        }
                    }
                }
            }
        });
    };

    this.makeCrossDomainCall = function (urldata, callback) {
        var result = "";
        var async = false;
        if (typeof callback == 'function' || typeof callback == 'string') {
            async = true;
        }
		if(typeof APP_TYPE != "undefined" && APP_TYPE == "ios" && !(urldata.indexOf('.json') > -1)) {
			if (urldata.indexOf('api/login/redirect') > -1) {
		        urldata = Platform.getAuthenticatedURL(urldata, 'GET', 'cloud');
		    } else if (urldata.indexOf('api/login/user') > -1) {
		        urldata = Platform.getAuthenticatedURL(urldata, 'POST', 'cloud');
		    } else {
	            var deviceId = Platform.getDeviceMacAddress();
	            if(deviceId != "")
	                urldata += "&deviceid=" + deviceId;
			}
		}
        $.ajax({
            type: "GET",
            url: urldata,
            async: async,
            crossDomain: true,
            success: function (data, textStatus, xhr) {
                if(typeof data == "object"){
                data = JSON.stringify(JSON.stringify(data));
                } else {
                    data = JSON.stringify($.trim(data));
                }
                result = '{"status":'+xhr.status+',"data":'+data+'}';
                if (typeof callback != 'undefined') {
                    if (typeof callback == 'function') {
                        return callback($.parseJSON(result));
                    }
                    else {
                        if(typeof window[callback] != 'undefined')
                            return window[callback]($.parseJSON(result));
                    }
                }
            },
            error: function(xhr, status, error) {
                status = xhr.status;
                result = '{"status":'+xhr.status+',"data":"'+ERROR_MESSAGE+'"}';
                var error_message = error.message ? error.message : error;
                if(status != "404")
                    console.log("url = " + urldata + " Error = " + error_message);
                if(status == "500" || status == "502" || status == "503" || status == "504" || status == "404"){
                    Client.closeProgressBar();
                    if(status != "404")
					    alert(ERROR_MESSAGE);
                }else if(status == "403"){
                    for(var i in LOGIN_RESTRICTED_URLS){
                        if(urldata.indexOf(LOGIN_RESTRICTED_URLS[i]) > 0){
                            return onAccessDenied();
                        }
                    }
                }
                if (typeof callback != 'undefined') {
                    if (typeof callback == 'function')
                        return callback($.parseJSON(result));
                    else
                        return window[callback]($.parseJSON(result));
                }
            }
        });
        return result;
    };

    this.makePostAsyncCall = function(urldata, callback, content, method, noredirect){
        var result = "";
        if(typeof method == 'undefined'){
            method = 'post';
        }
        if(!content || method == "get") {
            content = false;
        }
        var xhr_field = {
            withCredentials: true
        };

        // adding api_version to url
        if(urldata.indexOf('?') > -1){
            urldata += '&api_version=' + API_VERSION;
        }else{
            urldata += '?api_version=' + API_VERSION;
        }

        if (urldata.indexOf('.json') > -1) {
            return this.makeCrossDomainCall(urldata, callback);
        }
		if(typeof APP_TYPE != "undefined" && APP_TYPE == "ios") {
			if (urldata.indexOf('api/login/redirect') > -1) {
		        urldata = Platform.getAuthenticatedURL(urldata, 'GET', 'cloud');
		    } else if (urldata.indexOf('api/login/user') > -1) {
		        urldata = Platform.getAuthenticatedURL(urldata, 'POST', 'cloud');
		    } else {
	            var deviceId = Platform.getDeviceMacAddress();
	            if(deviceId != "")
	                urldata += "&deviceid=" + deviceId;
			}
		}
        $.ajax({
            type: method,
            url: urldata,
            dataType: 'text',
            data: content,
            xhrFields: xhr_field,
            crossDomain: true,
            contentType: "application/x-www-form-urlencoded",
            async: true,
            success: function (data, textStatus, xhr) {
                if (typeof noredirect != "undefined" && noredirect == "true") {
                    return Platform.makeCrossDomainCall(data.url, callback)
                } else {
	                if(typeof data == "object"){
                    data = JSON.stringify(JSON.stringify(data));
                    } else {
	                    data = JSON.stringify($.trim(data));
	                }
	                result = '{"status":'+xhr.status+',"data":'+data+'}';
	                if (typeof callback != 'undefined') {
	                    if (typeof callback == 'function') {
	                        callback($.parseJSON(result));
	                    }
	                    else {
	                        if(typeof window[callback] != 'undefined')
	                            window[callback]($.parseJSON(result));
	                    }
	                }
                }
            },
            error: function(xhr, status, error) {
                status = xhr.status;
                result = '{"status":'+xhr.status+',"data":"'+ERROR_MESSAGE+'"}';
                var error_message = error.message ? error.message : error;
                if(status != "404")
                    console.log("url = " + urldata + " Error = " + error_message);
                if(status == "500" || status == "502" || status == "503" || status == "504" || status == "404"){
                    Client.closeProgressBar();
                    if(status != "404")
					    alert(ERROR_MESSAGE);
                }else if(status == "403"){
                    for(var i in LOGIN_RESTRICTED_URLS){
                        if(urldata.indexOf(LOGIN_RESTRICTED_URLS[i]) > 0){
                            return onAccessDenied();
                        }
                    }
                }
                if (typeof callback != 'undefined') {
                    if (typeof callback == 'function')
                        callback($.parseJSON(result));
                    else
                        window[callback]($.parseJSON(result));
                }
            }
        });
    }

    this.makeAsyncTaskCall = function (jsonString){
        var noredirect = "";
        var content = $.parseJSON(jsonString);

        var urldata = content.url;

        var json = content.data;

        var callback = content.callback;
        if (typeof content.noredirect != "undefined") {
            noredirect = content.noredirect
        }

        this.makePostAsyncCall(urldata, callback, json, content.method, noredirect);
    }

    this.parseresult = function(result,resulttype)
    {
         if(resulttype === "boolean")
         {
                if(result === "true")
                    return true;
                else
                    return false;
         }
         else if(resulttype === "integer")
         {
             return parseInt(result)
         }
    };


    /* needs to be checked
     * Used in bootstrap, sync and notes*/
    this.saveObject = function (objType, objId, objName, objValue)
    {
        if (objType == "currentuser") {
            Platform.saveInLocalStorage("currentuser", objValue);
            return;
        } else if (objType == "videofeedback") {
            ALL_VIDEO_FEEDBACK = {};
            var url = OBJECT_SERVER_DOMAIN + "/api/set/videofeedback?auth=" + AUTH_ENABLED + "&uid=" + OBJECT_USER_ID;
            var value = $.parseJSON(decodeURIComponent(objValue));
            delete value.id;
            delete value.feedback_id;
            this.makePostAsyncCall(url, "", encodeURIComponent(JSON.stringify(value)), "post");
            return;
        }  else if (objType == "scorm_result") {
            var value = $.parseJSON(objValue);
            var url = OBJECT_SERVER_DOMAIN + "/api/scormsetvalues?auth=true&uid=" + OBJECT_USER_ID + "&nid=" + value.nid
            var value = $.parseJSON(decodeURIComponent(objValue));
            this.makePostAsyncCall(url, "", encodeURIComponent(JSON.stringify(value)), "post");
            return;
        } 
        else if (objType == "coachmark") {
            ALL_COACH_MARK_DATA = [];
            var url = OBJECT_SERVER_DOMAIN + "/api/set/coachmark?auth=" + AUTH_ENABLED + "&uid=" + OBJECT_USER_ID;
            var value = $.parseJSON(objValue);
            var postdata = encodeURIComponent(JSON.stringify(value[value.length - 1]));
            this.makePostAsyncCall(url, "", postdata, "post");
            return;
        } else if(objType == "quiz_result") {
            this.saveInLocalStorage("quiz_result-" + OBJECT_USER_ID + "-" + objId, objValue);
            return;
        } else if(objType == "userProfile") {
            OBJECT_USER_PROFILE = $.parseJSON(objValue);
            return;
        } else if (objType == OBJECT_QUIZ) {
            if (typeof OBJECT_FROM_ID != "undefined") {
                if (!OBJECT_FROM_ID[OBJECT_QUIZ]) {
                    OBJECT_FROM_ID[OBJECT_QUIZ] = {};
                }
                OBJECT_FROM_ID[OBJECT_QUIZ][objId] = objValue;
            }
        }
        return "";
    };

    this.saveDataToFileSystem = function(){
        return "";
    }

    this.navigate = function(url){
        window.location.href = url;
    }

    this.makeCall = function(number){
        window.location.href="tel://"+number;
    }

    this.setUserID = function (uid)
    {
        return "";
    };

    /* needs to be checked
     * No similar API
     * Used in sdcard registration */
    this.saveEncryptObject = function(objType, objId, objName, objValue)
    {
        var content = [objType, objId, objName,objValue];

        var urldata = "/platformservice/api/platformutil/saveEncryptObject";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);
    }

    /* not used anywhere */
    this.saveFile = function(filePath, cid)
    {
        var content = [filePath, cid];

        var urldata = "/platformservice/api/platformutil/saveFile";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata, json);
    };

    this.getUserNotification = function(){
        var notifications = {}; notifications['notification'] = [];
        var url = OBJECT_SERVER_DOMAIN + "/api/getallnotifications?uid="+OBJECT_USER_ID +"&showall=true";
        var returndata = this.makeAjaxCall(url);
        if(typeof returndata !== 'object'){
            notifications['notification'] = $.parseJSON(returndata);
        }else{
            notifications['notification'] = returndata;
        }
        return JSON.stringify(notifications);
    }

    this.getUnreadNotification = function(callback){
        var notifications = {}; notifications['notification'] = [];
        var url = OBJECT_SERVER_DOMAIN + "/api/getunreadnotification?uid="+OBJECT_USER_ID;
        var returndata = this.makeAjaxCall(url);
        if (typeof callback == "undefined"){
            return returndata;
        }
        if(typeof returndata !== 'object'){
            return returndata;
        }else{
            returndata = Number(returndata.length);
        }
        if(typeof callback != 'undefined' && callback){
            return callback(returndata);
        }else{
            return returndata;
        }
    }

    this.getNotificationUnReadCount = function(callback){
        if(LAST_SHOWN_NOTIFICATION_TIME > 0){
            var startTime = LAST_SHOWN_NOTIFICATION_TIME;
            var curTime = new Date().getTime();
            var difference = curTime - startTime; // This will give difference in milliseconds
            var resultInMinutes = Math.round(difference / 60000);
            if(resultInMinutes < 2){
                return callback(NOTIFICATIONS_COUNT);
            }
        }
        var notifications = {}; notifications['notification'] = [];
        var url = OBJECT_SERVER_DOMAIN + "/api/getallnotifications?uid="+OBJECT_USER_ID +"&showall=false";
        this.makeGetAsyncCall(url, function(returndata){
            if(typeof returndata !== 'object'){
                notifications['notification'] = $.parseJSON(returndata);
            }else{
                notifications['notification'] = returndata;
            }
            NOTIFICATIONS_COUNT = notifications['notification'].length;
            LAST_SHOWN_NOTIFICATION_TIME = new Date().getTime();
            return callback(NOTIFICATIONS_COUNT);
        });
    }
    this.getUnreadNotificationCount=function(callback){
        var url = OBJECT_SERVER_DOMAIN + "/api/getunreadnotificationcount?uid="+ OBJECT_USER_ID+"auth="+AUTH_ENABLED;
        this.makeGetAsyncCall(url, function(returndata){
            if(typeof returndata !== 'object'){
                returndata = $.parseJSON(returndata);
            }else{
                returndata = Number(returndata.notification_count);
            }
            return callback(returndata);
        });
    }

    this.updateNotificationReadStatus = function(OBJECT_USER_ID,nid,notification_type){
        url = OBJECT_SERVER_DOMAIN + "/api/updatenotificationseen?uid="+OBJECT_USER_ID+"&type="+notification_type;
        this.makeAjaxCall(url);
        LAST_SHOWN_NOTIFICATION_TIME = 0;
    }

    this.handleProtocolInRelativeURL=function(url){
        if (url.startsWith("//") && url.indexOf("http") == -1) {
            if(OBJECT_SERVER_DOMAIN.indexOf("https") > -1){
                url = 'https:' + url;
            }
            else{
                url = 'http:' + url;
            }
        }
        return url;
    }

    this.getObjectProperty = function (objType, objId, objName)
    {
        var url = "";
        if(typeof OBJECT_VIDEO_WEBINAR != "undefined" && objType == OBJECT_VIDEO_WEBINAR){
            objType = OBJECT_VIDEO;
        }
        if(objType == "user" || objType == "currentuser" || typeof objId == "undefined"){
            if (objType == "currentuser") {
                return this.getFromLocalStorage("currentuser");
            }
            return "";
        }
        if(OBJECT_FROM_ID.hasOwnProperty(objType)){
            if(OBJECT_FROM_ID[objType].hasOwnProperty(objId)){
                return OBJECT_FROM_ID[objType][objId];
            }
        }
        else{
            OBJECT_FROM_ID[objType] = {};
        }
        if(objType == OBJECT_CATEGORY){
            if(CATEGORY_DETAILS.length > 0){
                var cat = getObjectById(CATEGORY_DETAILS,objId,"nid");
                if(typeof cat == "undefined"){
                    cat = getObjectById(CATEGORY_DETAILS,objId,"tid");
                }
                var activeLanguageCode = Platform.getPreference("lang");
                if(typeof cat['locale'][activeLanguageCode] != 'undefined'){
                    cat['title'] =  cat['locale'][activeLanguageCode]['title'];
                    cat['description'] =   cat['locale'][activeLanguageCode]['description'];
                    cat['summary'] =  cat['locale'][activeLanguageCode]['summary'];
                };
                if(typeof cat != "undefined" && cat != null){
                    return JSON.stringify(cat);
                }
            }

            url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_CATEGORY + 'fromid?catid=' + objId + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET
            + '&lang='+ Platform.getProperty('lang');        
        } else if(objType == OBJECT_BOOK){
            url = OBJECT_SERVER_DOMAIN + "/api/get"+OBJECT_VIDEO+"fromid?nid=" + objId + '&lang='+ Platform.getProperty('lang');
        }
        else if(objType == OBJECT_VIDEO){
            url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_VIDEO + 'fromid?nid=' + objId + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&format='+RESOURCE_FORMAT + '&lang='+ Platform.getProperty('lang');
        }
        else if(objType == OBJECT_QUIZ){
            url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUIZ + 'fromid?nid=' + objId + '&uid=' + OBJECT_USER_ID + '&lang='+ Platform.getProperty('lang');
        }
        else if(objType == OBJECT_COURSE){
            if(COURSE_DETAILS.hasOwnProperty(objId)){
                return JSON.stringify(COURSE_DETAILS[objId]);
            }
            url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_COURSE + 'fromid?nid=' + objId + '&uid=' + OBJECT_USER_ID + '&lang='+ Platform.getProperty('lang');
        }
        else if(objType == OBJECT_CHAPTER){
            url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_CHAPTER + 'fromid?nid=' + objId + '&uid=' + OBJECT_USER_ID + '&lang='+ Platform.getProperty('lang');
        }
        else if(objType == OBJECT_VIDEO_WEBINAR){
            url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_VIDEO + 'fromid?nid=' + objId + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&format='+RESOURCE_FORMAT + '&lang='+ Platform.getProperty('lang');
        }
        else if(objType == OBJECT_SUPPORT_DOCS){
            url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_VIDEO + 'fromid?nid=' + objId + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&format='+RESOURCE_FORMAT + '&lang='+ Platform.getProperty('lang');
        }
        else if(objType == OBJECT_NOTES){
            return JSON.stringify(NOTES_LIST[objId]);
        }
        else if(objType == OBJECT_GROUP){
            if(typeof OBJECT_USER_GROUPS_JSON != 'undefined' && OBJECT_USER_GROUPS_JSON != null && OBJECT_USER_GROUPS_JSON != "" && OBJECT_USER_GROUPS_JSON.length > 0){
               var group = getObjectById(OBJECT_USER_GROUPS_JSON,objId,"nid");
               return JSON.stringify(group);
            }
            else{
               return "";
            }
        }
        else if(objType == "quiz_result"){
            if(QUIZ_RESULT.hasOwnProperty(objType)){
                var quiz_res = getObjectById(QUIZ_RESULT.quiz_result,objId,"result_id");
                if(typeof quiz_res != "undefined" && quiz_res != null){
                    return JSON.stringify(quiz_res);
                }
            }
            if(QUIZ_RESULT_BY_ID.hasOwnProperty(objType)){
                var quiz_res = getObjectById(QUIZ_RESULT_BY_ID.quiz_result,objId,"result_id");
                if(typeof quiz_res != "undefined" && quiz_res != null){
                    return JSON.stringify(quiz_res);
                }
            }
        } else if (objType == 'trailuserinfo') {
            url = OBJECT_SERVER_DOMAIN + '/api/get/trailconfiguration?auth=' + AUTH_ENABLED;
        } else if (objType == "video_settings") {
            url = OBJECT_SERVER_DOMAIN + '/api/get/videosettings?auth=' + AUTH_ENABLED;
        } else if (objType == "videofeedbackoption" && typeof GET_VIDEO_FEEDBACK_SETTINGS != "undefined") {
            url = GET_VIDEO_FEEDBACK_SETTINGS;
        } else if (objType == 'coachmark') {
            return this.getObjectByID('temp_coachmark_');
        } else if (objType == 'lang') {
            var allLang = this.getAllLanguages();
            var lang = getObjectById(allLang, objId, "tid");
            if(typeof lang != "undefined" && lang != null){
                return JSON.stringify(lang);
            }
            return "";
        } else if (objType == 'userProfile') {
            if(typeof OBJECT_USER_PROFILE != "undefined" && OBJECT_USER_PROFILE.length > 0){
                return JSON.stringify(OBJECT_USER_PROFILE);
            } else {
                return "";
            }
        } else {
            return "";
        }
        var returndata = this.makeAjaxCall(url);
        if(typeof returndata !== 'object'){
            returndata = $.parseJSON(returndata);
        }
        if (returndata == null) {
            return '';
        } else {
            var value = returndata[0];
            if(!value) {
                value = returndata;
            }
            OBJECT_FROM_ID[objType][objId] = JSON.stringify(value);
            return JSON.stringify(value);
        }
    };

    this.getEncryptObjectProperty = function (objType, objId, objName)
    {
        var content = [objType, objId, objName];

        var urldata = "/platformservice/api/platformutil/getEncryptObjectProperty";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);
    };

    this.getObjects = function (objType, objId, objName)
    {
        var content = [objType, objId, objName];

        var urldata = "/platformservice/api/object/getObjects";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json,"","get");
    };

    /* needs to be checked
     * No SImilar API
     * used in quiz, quiz results*/
    this.getObjectsOfType = function (objType)
    {
        var url = "";
        if(objType == OBJECT_CATEGORY){
            url = OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_CATEGORIES + '?uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&lang='+ Platform.getProperty('lang');
        }else if(objType == OBJECT_COURSE){
            if(ALL_COURSES.hasOwnProperty(objType) > 0){
                return ALL_COURSES[objType];
            }
            url = OBJECT_SERVER_DOMAIN + '/api/getall' +OBJECT_COURSE+ 's?uid=' + OBJECT_USER_ID + '&lang='+ Platform.getProperty('lang');
        }else if(objType == "quiz_result"){
            if(QUIZ_RESULT.hasOwnProperty(objType)){
                    return JSON.stringify(QUIZ_RESULT);
            }
            url = OBJECT_SERVER_DOMAIN + '/api/getquizresults?uid=' + OBJECT_USER_ID;
        }else if(objType == "lang"){
            url = OBJECT_SERVER_DOMAIN + '/api/getalllanguages?uid=' + OBJECT_USER_ID;
        }
        if(url == ""){
            return "";
        }
        var returndata = this.makeAjaxCall(url);
        if(typeof returndata !== 'object'){
            returndata = $.parseJSON(returndata);
        }
        if (objType == OBJECT_CATEGORY) {
            var result = {};
            CATEGORY_DETAILS = returndata
            result[OBJECT_CATEGORY] = returndata;
            return JSON.stringify(result);
        }
        if(objType == OBJECT_COURSE){
            ALL_COURSES[objType] = JSON.stringify(returndata[0]);
        }
        if(objType == "lang"){
            var result = {};
            result["lang"] = returndata;
            return JSON.stringify(result);
        }
        if(objType == "quiz_result"){
            QUIZ_RESULT.quiz_result = returndata;
            return JSON.stringify(QUIZ_RESULT);
        }
        return JSON.stringify(returndata[0]);
    };

    this.uploadQuiz = function (data, callback)
    {
        var url = OBJECT_SERVER_DOMAIN + "/api/quiz/submit?uid="+OBJECT_USER_ID;
        this.makePostAsyncCall(url, function (returndata) {
            if(typeof returndata !== 'object'){
                returndata = $.parseJSON(returndata);
            }
            var status = returndata['status'];
            if (status == 200) {
                QUIZ_RESULT = {};
                QUIZ_RESULT_BY_ID = {};
                SUBMITTED_RESULT_ID = $.parseJSON(returndata["data"]).result_id;
                result = status;
            } else if (status == 403) {
                result = USER_LOGOUT_ERROR;
            } else {
                result = QUIZ_NOT_SUBMITTED;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }, JSON.stringify(data), "post");
    };

    /* needs to be checked
     * Used in sdcard registration*/
    this.getEncryptObjectsOfType = function (objType)
    {
        var content = [objType];

        var urldata = "/platformservice/api/platformutil/getEncryptObjectsOfType";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);
    };

    /* needs to be checked
     * No SImilar API
     * used in sync*/
    this.removeObject = function (objType, objId)
    {
        if(objType == 'quiz_result'){
            this.deleteFromLocalStorage('quiz_result-' + OBJECT_USER_ID + '-' + objId);
        }
    };

    this.searchObject = function (objname, objType, searchlimit, page, uid, groupid, category, activecatid,  coursefilter, ignore_params, callback)
    {
        var url = "";
        var returndata = {};
        if (objType == '') {
            objType = 'all';
        }
        url = OBJECT_SERVER_DOMAIN + '/api/search' + '?text=' + objname + '&type=' + objType + '&limit=' + searchlimit + '&page='+ page + '&'+category+'=' + activecatid + '&uid=' + uid + '&groupid='+ groupid;
        if (url == "") {
            return "";
        }
        this.makeGetAsyncCall(url, function (response) {
            returndata['search'] = response;
            if (typeof returndata !== 'object') {
                returndata = $.parseJSON(returndata);
            }
            callback(JSON.stringify(returndata));
        });
    };

    /* needs to be checked
     * No SImilar API
     * Used in sync*/
    this.getObjectType = function (objId)
    {
        return OBJECT_VIDEO;
    };

    /* needs to be checked
     * No SImilar API
     * Used in sync*/
    this.addRelation = function (objectType, objectId, relationObjectType, relationObjectId, offset)
    {
        if(objectType == OBJECT_QUIZ && relationObjectType == "quiz_result"){
            this.saveInLocalStorage(objectId+'-'+OBJECT_USER_ID+'-quiz_result', relationObjectId);
        }
        return "";
    };

    /* needs to be checked
     * No SImilar API
     * Used in sync*/
    this.addAllRelation = function (array)
    {
        var content = [array];

        var urldata = "/platformservice/api/object/addAllRelation";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);
    };

    /* needs to be checked
     * No SImilar API
     * Used in sync*/
    this.removeAllRelation = function (array)
    {
        var content = [array];

        var urldata = "/platformservice/api/object/removeAllRelation";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);
    };

    /* needs to be checked
     * No SImilar API
     * Used to get totalvideowatched duration in the app for 40 hours flow*/
    this.getTotalVideoWatchedDuration = function (userid)
    {
        if(typeof TOTAL_WATCHED_DURATION != "undefined" && TOTAL_WATCHED_DURATION != "") {
            return TOTAL_WATCHED_DURATION;
        }
        var url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_VIDEO + 'watchedduration?uid=' + OBJECT_USER_ID;
        var result = $.parseJSON(this.makeAjaxPostCall(url));

        if (result["status"] == "200") {
            var resultjson = $.parseJSON(result["data"]);
            TOTAL_WATCHED_DURATION = resultjson['duration'];
            return resultjson['duration'];
        }
        return "";
    };

    /* Not used anywhere */
    this.getRelationsOfType = function (objectType, objectId, relationObjectType)
    {
        return "";
    };

    /* needs to be checked
     * No SImilar API
     * Used in sync*/
    this.removeRelation = function (objType, objId, relationObjectType, relationObjectId)
    {
        if(objType == OBJECT_QUIZ && (relationObjectType == 'quiz_result' || relationObjectType == 'attempt_log')){
            this.deleteFromLocalStorage(objId +'-'+ OBJECT_USER_ID +'-'+ relationObjectType);
        }
    };

    /* needs to be checked
     * No SImilar API
     * Used in search*/
    this.getObjectFromRelations = function (relobjType, relobjId, key)
    {
        var content = [relobjType, relobjId, key];

        var urldata = "/platformservice/api/object/getObjectFromRelations";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json,"","get");
    };

    this.getRelatedObjects = function (objType, objId, relObjType)
    {
        try {
            var url = "";
            var resultjson = {};
            var courseService = CONTROLLER.getService("Course");
            if (objType == OBJECT_GROUP && relObjType == OBJECT_CATEGORY) {
                if (ALL_CATEGORIES.hasOwnProperty(objId)) {
                    return ALL_CATEGORIES[objId];
                }
                url = OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_CATEGORIES + '?uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&groupid=' + objId  + '&lang='+ Platform.getProperty('lang');
            } else if (objType == OBJECT_CATEGORY && relObjType == OBJECT_COURSE) {
                url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_COURSE + 'sfor' + OBJECT_CATEGORY + '?catid=' + objId + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET  + '&lang='+ Platform.getProperty('lang');
            } else if (objType == OBJECT_COURSE && relObjType == OBJECT_CHAPTER) {
                var coursedetails = courseService.getCourseDetails(objId);
                if (typeof coursedetails == "object") {
                    resultjson[relObjType] = coursedetails[relObjType];
                    return JSON.stringify(resultjson);
                }
                url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_CHAPTER + 'sfor' + OBJECT_COURSE + '?nid=' + objId + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&lang='+ Platform.getProperty('lang');
            } else if (objType == OBJECT_CHAPTER && relObjType == OBJECT_VIDEO) {
                var courseid = CONTROLLER.getVariable("activecourseid");
                if (courseid) {
                    var coursedetails = courseService.getCourseDetails(courseid);
                    for (var i = 0; i < coursedetails[OBJECT_CHAPTER].length; i++) {
                        if (coursedetails[OBJECT_CHAPTER][i]["nid"] == objId) {
                            resultjson[relObjType] = coursedetails[OBJECT_CHAPTER][i][relObjType];
                            return JSON.stringify(resultjson);
                            break;
                        }
                    }
                }
                url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_VIDEO + 'sfor' + OBJECT_CHAPTER + '?nid=' + objId + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&format=' + RESOURCE_FORMAT + '&lang='+ Platform.getProperty('lang');
            } else if (objType == OBJECT_COURSE && relObjType == OBJECT_BOOK) {
                var courseid = CONTROLLER.getVariable("activecourseid");
                var coursedetails = courseService.getCourseDetails(courseid);

                for (var i = 0; i < coursedetails[OBJECT_CHAPTER].length; i++) {
                    if (coursedetails[OBJECT_CHAPTER][i]["nid"] == objId) {
                        resultjson[relObjType] = coursedetails[OBJECT_CHAPTER][i][relObjType];
                        return JSON.stringify(resultjson);
                        break;
                    }
                }
                url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_BOOK + 'sof' + OBJECT_COURSE + '?nid=' + objId + '&uid=' + OBJECT_USER_ID + '&lang='+ Platform.getProperty('lang');
            } else if (objType == OBJECT_COURSE && relObjType == OBJECT_QUIZ) {
                var courseid = CONTROLLER.getVariable("activecourseid");
                var coursedetails = courseService.getCourseDetails(courseid);

                for (var i = 0; i < coursedetails[OBJECT_CHAPTER].length; i++) {
                    if (coursedetails[OBJECT_CHAPTER][i]["nid"] == objId) {
                        resultjson[relObjType] = coursedetails[OBJECT_CHAPTER][i][relObjType];
                        return JSON.stringify(resultjson);
                        break;
                    }
                }
                url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUIZ + 'of' + OBJECT_COURSE + '?nid=' + objId + '&lang='+ Platform.getProperty('lang');
            } else if (objType == OBJECT_QUIZ && relObjType == "quiz_result" || objType == OBJECT_TEST_COLLECTION && relObjType == "quiz_result") {
                if (QUIZ_RESULT_BY_ID.hasOwnProperty(relObjType) && QUIZ_RESULT_BY_ID.quiz_result.hasOwnProperty(objId)) {
                    resultjson[relObjType] = $.parseJSON(JSON.stringify(QUIZ_RESULT_BY_ID.quiz_result.objId)) || [];
                    var local_result = this.getLocalStorageResult(objId, OBJECT_USER_ID)
                    if (local_result) {
                        resultjson[relObjType].push(JSON.parse(local_result));
                    }
                    return JSON.stringify(resultjson);
                }else{
                    QUIZ_RESULT_BY_ID.quiz_result = {};
                }
                url = OBJECT_SERVER_DOMAIN + '/api/getquizresults?quiz_id=' + objId + '&uid=' + OBJECT_USER_ID;
            } else if (objType == OBJECT_CHAPTER && relObjType == OBJECT_SUPPORT_DOCS) {
                var courseid = CONTROLLER.getVariable("activecourseid");
                var coursedetails = courseService.getCourseDetails(courseid);

                for (var i = 0; i < coursedetails[OBJECT_CHAPTER].length; i++) {
                    if (coursedetails[OBJECT_CHAPTER][i]["nid"] == objId) {
                        resultjson[relObjType] = coursedetails[OBJECT_CHAPTER][i]["support_docs"];
                        return JSON.stringify(resultjson);
                        break;
                    }
                }
                url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_SUPPORT_DOCS + '?nid=' + objId + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&lang='+ Platform.getProperty('lang');
            } else if (objType == OBJECT_VIDEO && relObjType == OBJECT_VIDEO_INDEX) {
                url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_VIDEO_INDEX + '?nid=' + objId;
            } else if (objType == OBJECT_VIDEO && relObjType == "videofeedback") {
                if (typeof ALL_VIDEO_FEEDBACK != 'undefined' && ALL_VIDEO_FEEDBACK.hasOwnProperty(relObjType)) {
                    var index = findIndexByKeyValue(ALL_VIDEO_FEEDBACK[relObjType],"vid", objId);
                    var data = ALL_VIDEO_FEEDBACK[relObjType][index];
                    var temp = {};
                    temp[relObjType] = [];
                    if(data) {
                        temp[relObjType].push(data);
                    }
                    return JSON.stringify(temp);
                }
                url = OBJECT_SERVER_DOMAIN + "/api/get/videofeedback?auth=" + AUTH_ENABLED + '&uid=' + OBJECT_USER_ID;
            } else if (objType == OBJECT_CATEGORY && relObjType == "tag") {
                var categoryIndex = findIndexByKeyValue(CATEGORY_DETAILS, "nid", objId);
                if (typeof ALL_TAGS != "undefined" && ALL_TAGS.length > 0 && categoryIndex != null) {
                    var category_tag = CATEGORY_DETAILS[categoryIndex]['tags'];
                    var index = findIndexByKeyValue(ALL_TAGS, "tag_tid", category_tag);
                    var data = ALL_TAGS[index];
                    var temp = {};
                    temp[relObjType] = [];
                    if(data) {
                        temp[relObjType].push(data);
                    }
                    return JSON.stringify(temp);
                }
                url = OBJECT_SERVER_DOMAIN + '/api/getalltags?nid=' + objId + '&uid=' + OBJECT_USER_ID + '&lang='+ Platform.getProperty('lang');
            }
            if (url == "") {
                return "";
            }
            var result = $.parseJSON(this.makeAjaxPostCall(url));

            if (result["status"] == "200") {
                resultjson[relObjType] = $.parseJSON(result["data"]);
                if (objType == OBJECT_GROUP && relObjType == OBJECT_CATEGORY) {
                    ALL_CATEGORIES[objId] = JSON.stringify(resultjson);
                } else if (objType == OBJECT_QUIZ && relObjType == "quiz_result") {
                    QUIZ_RESULT_BY_ID.quiz_result[objId] = {};
                    QUIZ_RESULT_BY_ID.quiz_result[objId] = $.parseJSON(JSON.stringify(resultjson.quiz_result)) || [];
                    var local_result = this.getLocalStorageResult(objId, OBJECT_USER_ID)
                    if (local_result) {
                        resultjson[relObjType].push(JSON.parse(local_result));
                    }
                } else if (objType == OBJECT_VIDEO && relObjType == OBJECT_VIDEO_INDEX) {
                    var temp = "";
                    var data = $.parseJSON(result["data"]);
                    if(data){
                        temp = data;
                    }
                    resultjson[relObjType] = temp;
                } else if (objType == OBJECT_VIDEO && relObjType == "videofeedback") {
                    ALL_VIDEO_FEEDBACK = resultjson;
                    var index = findIndexByKeyValue(ALL_VIDEO_FEEDBACK[relObjType],"vid", objId);
                    var temp = [];
                    var data = ALL_VIDEO_FEEDBACK[relObjType][index];
                    if(data){
                        temp.push(data);
                    }
                    resultjson[relObjType] = temp;
                } else if (objType == OBJECT_CATEGORY && relObjType == "tag") {
                    var categoryIndex = findIndexByKeyValue(CATEGORY_DETAILS, "nid", objId);
                    if (categoryIndex != null) {
                        var category_tag = CATEGORY_DETAILS[categoryIndex]['tags'];
                        ALL_TAGS = $.parseJSON(result["data"]);
                        var index = findIndexByKeyValue(ALL_TAGS, "tag_tid", category_tag);
                        var data = ALL_TAGS[index];
                        var temp = {};
                        temp[relObjType] = [];
                        if(data) {
                            temp[relObjType].push(data);
                        }
                        return JSON.stringify(temp);
                    }
                }
                return JSON.stringify(resultjson);
            }
            return "";
        } catch (e) {
            console.log(e.message);
        }
        
    };

    this.getAllRelatedObjects = function (objType, objId, relObjType, filter)
    {
        var content = [objType, objId, relObjType, filter];

        var urldata = "/platformservice/api/object/getAllRelatedObjects";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json,"","get");
    };

    this.getRelatedObjectsFilter = function (objType, objId, relObjType, filter, callback)
    {
        var resultjson = {};
        filter = $.parseJSON(filter);
        if(objType == OBJECT_CATEGORY && relObjType == OBJECT_COURSE){
            if (filter[0].object_type == "group" && COURSES_FOR_CATEGORY[objId + "," + filter[0].object_id]) {
                if (typeof callback == "function") {
                    return callback(COURSES_FOR_CATEGORY[objId + "," + filter[0].object_id]);
                } else {
                    return COURSES_FOR_CATEGORY[objId + "," + filter[0].object_id];
                }
            } else if(filter[0].object_type == "groups" && COURSES_FOR_CATEGORY[objId + ",all"]){
                if (typeof callback == "function") {
                    return callback(COURSES_FOR_CATEGORY[objId + ",all"]);
                } else {
                    return COURSES_FOR_CATEGORY[objId + ",all"];
                }
            }
            var url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_COURSE + 'sfor' + OBJECT_CATEGORY + '?catid=' + objId + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&groupid=' + filter[0].object_id + '&lang='+ Platform.getProperty('lang');     
        } else if (objType == OBJECT_COURSE && relObjType == OBJECT_CHAPTER) {
            var courseService = CONTROLLER.getService("Course");
            var coursedetails = courseService.getCourseDetails(objId, filter[0].object_id);
            if (typeof coursedetails == "object" && Object.keys(coursedetails).length > 0) {
                resultjson[relObjType] = coursedetails[relObjType];
                if (typeof callback == "function") {
                    callback(JSON.stringify(resultjson));
                } else {
                    return JSON.stringify(resultjson);
                }
            }
            var url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_CHAPTER + 'sfor' + OBJECT_COURSE + '?nid=' + objId + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&groupid=' + filter[0].object_id + '&lang='+ Platform.getProperty('lang');
        }

        var getRelatedObjectsFilterComplete = function (result) {
            if(result["status"] == "200"){
                resultjson[relObjType] = $.parseJSON(result["data"]);
                if (objType == OBJECT_CATEGORY && relObjType == OBJECT_COURSE) {
                    if (filter[0].object_type == "group") {
                        COURSES_FOR_CATEGORY[objId + "," + filter[0].object_id] = JSON.stringify(resultjson);
                    } else {
                        COURSES_FOR_CATEGORY[objId + ",all"] = JSON.stringify(resultjson);
                    }
                }
                if (typeof callback == "function") {
                    return callback(JSON.stringify(resultjson));
                } else {
                    return JSON.stringify(resultjson);
                }
            }
        }

        if (typeof callback == "function") {
            return this.makePostAsyncCall(url, getRelatedObjectsFilterComplete);
        } else {
            var response = $.parseJSON(this.makeAjaxPostCall(url));
            return getRelatedObjectsFilterComplete(response);
        }
    };

    this.getRelatedObjectByOffset = function (objType, objId, relObjType, relObjID, filter)
    {
        var content = [objType, objId, relObjType, relObjID, filter];

        var urldata = "/platformservice/api/object/getRelatedObjectByOffset";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json,"","get");
    };

    /* needs to be checked
     * Used in sync
     * No Similar API*/
    this.removeRelationOfType = function (objType, objId, relObjType)
    {
        return;
    };

    /* needs to be checked
     * Used for showing videoviewhistory
     * No Similar API*/
    this.getRelationObjectCount = function (p1, p2, p3, p4, p5, p6, callback)
    {
        try {
            var courseService = CONTROLLER.getService("Course");
            if (typeof p5 != "undefined" && p6 == OBJECT_SUPPORT_DOCS) {
                var coursedetails = courseService.getCourseDetails(CONTROLLER.getVariable("activecourseid"));
                var supportDocsLength = 0;
                for (var i = 0; i < coursedetails[OBJECT_CHAPTER].length; i++) {
                    supportDocsLength = supportDocsLength + coursedetails[OBJECT_CHAPTER][i]["support_docs"].length;
                }
                return supportDocsLength;
            }
            else if(p4.indexOf(OBJECT_VIDEO) >= 0){
                var filter = $.parseJSON(p4);
                var contentlength = 0;
                var courseid = filter[0].object_id;
                if (p2 == OBJECT_CATEGORY) {
                    if (courseid) {
                    	var coursedetails = courseService.getCourseDetails(courseid);
                        for (var i = 0; i < coursedetails[OBJECT_CHAPTER].length; i++) {
                            contentlength = contentlength + coursedetails[OBJECT_CHAPTER][i][OBJECT_VIDEO].length;
                        }
                        return contentlength;
                    } else {
                        if (CATEGORY_RESOURCE_COUNT.hasOwnProperty(p3)) {
                            if (typeof callback == "function") {
                                return callback(CATEGORY_RESOURCE_COUNT[p3]);
                            } else {
                                return CATEGORY_RESOURCE_COUNT[p3];
                            }
                        } else {
                            var url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_VIDEO + 'scountfor' + OBJECT_CATEGORY + 'toc?nid=' + p3 + '&uid=' + OBJECT_USER_ID+ '&groups='+ p1 + '&lang='+ Platform.getProperty('lang');
                            var getRelationObjectCountComplete = function (result) {
                                if (result["status"] == "200") {
                                    var resultjson = $.parseJSON(result["data"]);
                                    CATEGORY_RESOURCE_COUNT[p3] = resultjson['count'];
                                    if (typeof callback == "function") {
                                        return callback(CATEGORY_RESOURCE_COUNT[p3]);
                                    } else {
                                        return CATEGORY_RESOURCE_COUNT[p3];
                                    }
                                }
                            }
                            if (typeof callback == "function") {
                                return this.makePostAsyncCall(url, getRelationObjectCountComplete);
                            } else {
                                var response = $.parseJSON(this.makeAjaxPostCall(url));
                                return getRelationObjectCountComplete(response);
                            }
                        }
                    }
                }
                if(p2 == OBJECT_COURSE){
                    var coursedetails = courseService.getCourseDetails(p3);
                    for (var i = 0; i < coursedetails[OBJECT_CHAPTER].length; i++) {
                        contentlength = contentlength + coursedetails[OBJECT_CHAPTER][i][OBJECT_VIDEO].length;
                    }
                    return contentlength;
                }

            }
        } catch (e) {
            console.log(e.message);
        }

    };

    /* needs to be checked
     * Used for sync
     * No Similar API*/
    this.downloadForm = function (downloadUrl, formId)
    {
        var content = [downloadUrl, formId];

        var urldata = "/platformservice/api/platformutil/downloadForm";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);
    };

    /* needs to be checked
     * Used for file upload for ask an expert in
     * No Similar API*/
    this.uploadForm = function (postUrl, formId, data)
    {
        var content = [postUrl, formId, data];

        var urldata = "/platformservice/api/platformutil/uploadForm";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);
    };

    /* not used */
    this.uploadFile = function (uploadUrl, localUrl, sync)
    {
		if(typeof APP_TYPE != "undefined" && APP_TYPE == "ios") {
	        var deviceId = Platform.getDeviceMacAddress();
	        if(deviceId != "")
	            uploadUrl += "&deviceid=" + deviceId;
		}
        $.ajax({
            url : uploadUrl,
            data: localUrl,
            processData: false,
            contentType: false,
            type: 'POST',
            async: false,
            success: function (data, textStatus, xhr) {
                if(typeof data == "object"){
                    data = JSON.stringify(JSON.stringify(data));
                }
                else{
                    data = JSON.stringify($.trim(data));
                }
                result = '{"status":'+xhr.status+',"data":'+data+'}';
                THIS.addorUpdateObjectCache(urldata,result);
            },
            error: function(xhr, status, error) {
                status = xhr.status;
                result = '{"status":'+status+'}';
                var error_message = error.message ? error.message : error;
                console.log("url = " + urldata + " Error = " + error_message);
            }
        });
        return result;
    };

    /* needs to be checked
     * No Similar API*/
    this.isFileAvailable = function (filepath)
    {
        return '{"filestatus":"Not Available"}';
    };

    /* needs to be checked
     * No Similar API*/
    this.deleteFile = function (filepath)
    {
        var content = [filepath];

        var urldata = "/platformservice/api/platformutil/deleteFile";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json,"boolean");
    };

    /* needs to be checked
     * No Similar API*/
    this.deleteAllDownloadedResources = function ()
    {

        var urldata = "/platformservice/api/platformutil/deleteAllDownloadedResources";

        return this.makeAjaxPostCall(urldata);
    };

    /* needs to be checked
     * api/downloadcontent */
    this.downloadFile = function (json)
    {
//        var content = [json];
//
//        var urldata = "/platformservice/api/platformutil/downloadFile";
//
//        var json = JSON.stringify(content);
//
//        return this.makeAjaxPostCall(urldata,json);
        return "";
    };

    /* needs to be checked
     * No Similar API */
    this.downloadMultipleFile = function (json)
    {
        var content = [json];

        var urldata = "/platformservice/api/platformutil/downloadMultipleFile";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);
    };

    /* needs to be checked
     * No Similar API */
    this.isContentAvailable = function (nid, youtubeid, format, usertype, filetype)
    {
        return '{"filestatus":"Not Available"}';
    };

    /* needs to be checked
     * No Similar API */
    this.isMultipleContentAvailable = function (json)
    {
        return '{"filestatus":"Not Available"}';
    };

    /* needs to be checked
     * No Similar API */
    this.isContentPosterAvailableInFilesTable = function (nid, youtubeid, data)
    {
        return '{"filestatus":"Not Available"}';
    };

    /* needs to be checked
     * No Similar API */
    this.getDownloadFileProgress = function (fileName, nid, youtubeid)
    {
        var content = [fileName, nid, youtubeid];

        var urldata = "/platformservice/api/file/getDownloadFileProgress";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);
    };

    /* needs to be checked
     * No Similar API */
    this.pauseDownload = function (localurl)
    {
        var content = [localurl];

        var urldata = "/platformservice/api/platformutil/pauseDownload";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);

    };

    /* needs to be checked
     * No Similar API */
    this.resumeDownload = function (localurl)
    {
        var content = [localurl];

        var urldata = "/platformservice/api/platformutil/resumeDownload";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);

    };

    /* needs to be checked
     * No Similar API */
    this.cancelDownloads = function (localurl)
    {
        var content = [localurl];

        var urldata = "/platformservice/api/platformutil/cancelDownloads";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);

    };

    /* needs to be checked
     * No Similar API */
    this.getFilePauseState = function (localurl)
    {
        var content = [localurl];

        var urldata = "/platformservice/api/platformutil/getFilePauseState";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json,"boolean");
    };

    /* needs to be checked
     * No Similar API */
    this.getAllFilePauseState = function ()
    {
        var urldata = "/platformservice/api/platformutil/getAllFilePauseState";

        return this.makeAjaxPostCall(urldata,undefined,"boolean");
    };

    /* needs to be checked
     * No Similar API */
    this.isFileAvailableInInternalMemory = function (nid)
    {
        return '{"filestatus":"Not Available"}';
    };

    /* needs to be checked
     * No Similar API */
    this.isFilePresentInDocRoot = function (nid)
    {
        var content = [nid];

        var urldata = "/platformservice/api/platformutil/isFilePresentInDocRoot";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json,"boolean");
    };

    /* needs to be checked
     * No Similar API */
    this.deleteFileFromInternalMemory = function (nid)
    {
        var urldata = "/platformservice/api/platformutil/deleteFileFromInternalMemory";

        var content = [nid];

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);

    };

    /* needs to be checked
     * No Similar API */
    this.getFileDownloadStatus = function (url)
    {
        var content = [url];

        var urldata = "/platformservice/api/platformutil/getFileDownloadStatus";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);

    };


    /***** Util API *****/

    /* needs to be checked
     * No Similar API */
    this.getDeviceIMEINumber = function ()
    {

        var urldata = "/platformservice/api/icommonutil/getDeviceIMEINumber";

        return this.makeAjaxPostCall(urldata);
    };

    /* needs to be checked
     * No Similar API */
    this.getNetworkState = function ()
    {
        try{
            if(typeof JSPlatform != "undefined" && JSPlatform.getNetworkState() === 'false') {
                return false;
            }
            else {
                if (navigator) {
                    if (navigator.onLine) {
                        return true;
                    } else {
                        return false;
                    }
                }
                return true;
            }
        }catch(e){
            return true;
        }
    };

    /* needs to be checked
     * No Similar API */
    this.isExternalDriveInserted = function ()
    {
        var urldata = "/platformservice/api/icommonutil/isExternalDriveInserted";

        return this.makeAjaxPostCall(urldata,undefined,"boolean");


    };

    /* needs to be checked
     * No Similar API */
    this.isSDCARDWritable = function ()
    {
        var urldata = "/platformservice/api/commonutil/isSDCARDWritable";

        return this.makeAjaxPostCall(urldata,undefined,"boolean");


    };

    /* needs to be checked
     * No Similar API */
    this.getDataFolder = function ()
    {
        // var urldata = "/platformservice/api/constant/getDataFolder";
        //
        //
        // return this.makeAjaxCall(urldata);

    };

    /* needs to be checked
     * No Similar API */
    this.getResourceFolder = function ()
    {
        // var urldata = "/platformservice/api/constant/getResourceFolder";
        //
        // return this.makeAjaxCall(urldata);

    };

    /* needs to be checked
     * No Similar API */
    this.getApplicationFolder = function ()
    {
        // var urldata = "/platformservice/api/constant/getApplicationFolder";
        //
        // return this.makeAjaxCall(urldata);

    };

    /* needs to be checked
     * No Similar API */
    this.setDeviceOrientation = function (orientation)
    {
        try{
            if (typeof JSPlatform != "undefined") {
                if (orientation == "portrait"){
                    JSPlatform.setDeviceOrientation("portrait");
                }else if (orientation == "landscape"){
                    JSPlatform.setDeviceOrientation("landscape");
                }else if (orientation == "remove"){
                    JSPlatform.removeDeviceOrientation();
                }
            }
        }catch(e){}
    };

    /* needs to be checked
     * No Similar API */
    this.showMessage = function (message)
    {
        if ($('#toastWrapper').length == 0) {
            $('body').append("<div id='toastWrapper'><div id='toastMessage'></div></div>");
        }
        $('#toastMessage').html(message);
        setTimeout(function () {
            $('#toastWrapper').remove();
        }, 3000);
    };

    /* needs to be checked
     * No Similar API */
    this.clearCache = function ()
    {
        if(typeof THICK_CLIENT != "undefined" && THICK_CLIENT == false && typeof THIN_CLIENT_MODE != "undefined" && THIN_CLIENT_MODE == false){
            JSInterface.clearCache();
        }

        return "";
    };

    /* needs to be checked
     * No Similar API */
    this.closeApplication = function ()
    {
        window.history.go(0);
    };

    /* needs to be checked
     * No Similar API */
    this.setUserName = function (username)
    {
        var urldata = "/platformservice/api/preference/setUserName";

        var content = [username];

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);

    };

    /* needs to be checked
     * No Similar API */
    this.addOrUpdateProductExpiry = function (expiry)
    {
        return "";

    };

    /* needs to be checked
     * No Similar API */
    this.logMessage = function (msg)
    {
        var content = [msg];

        var urldata = "/platformservice/api/platformutil/logMessage";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);

    };

    /* needs to be checked
     * No Similar API */
    this.verifyHash = function (data, hash)
    {
        var content = [data, hash];

        var urldata = "/platformservice/api/platformutil/verifyHash";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json,"boolean");
    };

    /* needs to be checked
     * No Similar API */
    this.getMACAddress = function ()
    {
        return "";
    };

    this.getDeviceMacAddress = function ()
    {
        if (typeof DEVICE_ID != "undefined") {
            return DEVICE_ID;
        }
        if (typeof JSPlatform != "undefined") {
            DEVICE_ID = JSPlatform.getDeviceMacAddress();
            return DEVICE_ID;
        }
        return "";
    };

    /* needs to be checked
     * No Similar API */
    this.getDeviceType = function ()
    {
        var urldata = "/platformservice/api/icommonutil/getDeviceType";

        return this.makeAjaxCall(urldata);

    };

    /* needs to be checked
     * No Similar API */
    this.getSdCardCID = function ()
    {
        var urldata = "/platformservice/api/icommonutil/getSdCardCID";

        return this.makeAjaxPostCall(urldata);

    };

    /* needs to be checked
     * No Similar API */
    this.generateSdCardCID = function ()
    {
        var urldata = "/platformservice/api/icommonutil/generateSdCardCID";

        return this.makeAjaxPostCall(urldata);

    };

    /* needs to be checked
     * No Similar API */
    this.writeSDCardCIDFile = function (filename ,cid)
    {
        var content = [filename ,cid];

        var urldata = "/platformservice/api/platformutil/writeSDCardCIDFile";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);
    };

    /* needs to be checked
     * No Similar API */
    this.getMD5String = function (data)
    {
//        var content = [data];
//
//        var urldata = "/platformservice/api/commonutil/getMD5String";
//
//        var json = JSON.stringify(content);
//
//        return this.makeAjaxPostCall(urldata,json);
        return "";

    };

    /* needs to be checked
     * No Similar API */
    this.enableZoom = function (flag)
    {
        try{
          // JSPlatform.enableZoom(flag.toString());
        }catch(e){}
    };

    this.setZoomStatus = function (val)
    {
        try {
            if (typeof JSPlatform != "undefined")
                JSPlatform.setZoomStatus(val.toString());
        }catch(e){}
    };

    /* needs to be checked
     * No Similar API */
    this.getLocalServerPath = function ()
    {
        return window.location.origin;

    };

    /* needs to be checked
     * No Similar API */
    this.getDocumentRoot = function ()
    {
        var urldata = "/platformservice/api/config/getDocumentRoot";


        return this.makeAjaxCall(urldata);

    };

    /* needs to be checked
     * No Similar API */
    this.isFilePresentInDocRoot = function (path)
    {
//        var content = [path];
//
//        var urldata = "/platformservice/api/platformutil/isFilePresentInDocRoot";
//
//        var json = JSON.stringify(content);
//
//        return this.makeAjaxPostCall(urldata,json,"boolean");

        return "";

    };

    /* needs to be checked
     * No Similar API */
    this.deleteFileFromDocRoot = function (path)
    {
        var urldata = "/platformservice/api/platformutil/deleteFileFromDocRoot";

        var content = [path];

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);


    };

    /* needs to be checked
     * No Similar API */
    this.getObfuscatedPath = function (path)
    {
        var urldata = "/platformservice/api/platformutil/getObfuscatedPath";

        var content = [path];

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);

    };

    /* needs to be checked
     * No Similar API */
    this.getAuthenticatedURL = function (url,method,type)
    {
        if (typeof JSPlatform != "undefined") {
            var deviceId = Platform.getDeviceMacAddress();
            if(deviceId != "")
                url += "&deviceid=" + deviceId;
            return JSPlatform.getAuthenticatedUrl(url, method);
        }
        return url;
    };

    /* needs to be checked
     * No Similar API */
    this.keepScreenOn = function ()
    {
        var urldata = "/platformservice/api/platformutil/keepScreenOn";

        return "";

    };

    /* needs to be checked
     * No Similar API */
    this.keepScreenOff = function ()
    {
        var urldata = "/platformservice/api/platformutil/keepScreenOff";

        return "";

    };

    /* needs to be checked
     * No Similar API */
    this.getLocalIPAddress = function ()
    {
        var urldata = "/platformservice/api/icommonutil/getLocalIPAddress";


        return this.makeAjaxCall(urldata);

    };

    /* needs to be checked
     * No Similar API */
    this.getGoogleAccount = function ()
    {
//        var urldata = "/platformservice/api/platformutil/getGoogleAccount";
//
//        return this.makeAjaxCall(urldata);

          return "";

    };

    /* needs to be checked
     * No Similar API */
    this.getApiDomain = function ()
    {
        // var urldata = "/platformservice/api/config/getApiDomain";
        //
        // return this.makeAjaxCall(urldata);
        return "/";

    };

    this.getPortalDomain = function ()
    {
        // var urldata = "/platformservice/api/config/getApiDomain";
        //
        // return this.makeAjaxCall(urldata);
        return "/";

    };

    this.getCloudFrontUrl = function ()
    {
        // var urldata = "/platformservice/api/config/getCloudFrontUrl";
        //
        // return this.makeAjaxCall(urldata);

    };

    this.getVideoCdnUrl = function ()
    {
        // var urldata = "/platformservice/api/config/getVideoCdnUrl";
        //
        // return this.makeAjaxCall(urldata);

    };

    this.getVideoImageServerUrl = function ()
    {
        var urldata = "/platformservice/api/config/getVideoImageServerUrl";

        return this.makeAjaxCall(urldata);

    };

    this.setDebugMode = function ()
    {
        return "";

    };

    this.isSystemDateChanged = function ()
    {
        var urldata = "/platformservice/api/platformutil/isSystemDateChanged";

        return this.makeAjaxPostCall(urldata);

    };

    this.isUserExistInDB = function (jsonstring)
    {
        return "true";
    };

    this.setUserID = function (id)
    {
        return "";
    };

    this.getApplicationVersionName = function (id)
    {
        return "1.0.0";
    };

    this.setStatusAndNavigationBar = function (flag)
    {
        var urldata = "/platformservice/api/platformutil/setStatusAndNavigationBar?param0=" + flag;

        return "";

    };

    this.deleteDatabase = function ()
    {
        try{
            if (typeof JSPlatform != "undefined") {
                JSPlatform.deleteDatabase();
                return true;
            }
            else {
                this.deleteFromLocalStorage("currentuser");
                this.deleteFromLocalStorage("allPreferences");
                return true;
            }
        }catch(e){}
    };



    /**** User Subscription Apis *****/

    this.addOrUpdateUserSubscription = function (jsonstring)
    {
        var content = [jsonstring];

        var urldata = "/platformservice/api/object/addOrUpdateUserSubscription";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);

    };

    this.getUserSubscription = function (jsonstring)
    {
        var jsonObj = $.parseJSON(jsonstring);
        if (typeof USER_SUBSCRIPTIONS == "undefined" || !USER_SUBSCRIPTIONS) {
            var url = OBJECT_SERVER_DOMAIN + "/api/user/subscriptions?uid=" + OBJECT_USER_ID;
            var result = $.parseJSON(this.makeAjaxPostCall(url));
            if (result["status"] == "200") {
                USER_SUBSCRIPTIONS = $.parseJSON(result["data"]);
            } else if (result["status"] == "403") {
                return ACCESS_DENIED;
            } else {
                return "";
            }
        }
        var data = USER_SUBSCRIPTIONS;
        if (jsonObj) {
            for (var key in data) {
                if (data.hasOwnProperty(key)) {
                    if (jsonObj.sku_id) {
                        if (data[key].sku_id == jsonObj.sku_id) {
                            return JSON.stringify(data[key]);
                        }
                    } else if (jsonObj.collection_id) {
                        if (data[key].collection_id == jsonObj.collection_id) {
                            return JSON.stringify(data[key]);
                        }
                    } else if (jsonObj.group_id) {
                        if (data[key].group_id == jsonObj.group_id) {
                            return JSON.stringify(data[key]);
                        }
                    }
                }
            }
            return JSON.stringify(data);
        } else {
            return USER_SUBSCRIPTIONS;
        }
        return "";
    };

    this.getUserSubscriptionByGroup = function (data, groupArray) {
        data = $.parseJSON(data);
        data.group_id = $.parseJSON(groupArray)[0];
        var subscription = this.getUserSubscription(JSON.stringify(data));
        if(subscription == "" || subscription == "{}" || subscription == '[]'){
            return "";
        }
        else if(subscription == ACCESS_DENIED){
            return ACCESS_DENIED;
        }
        else{
            return subscription;
        }
    }

    this.getAllUserSubscribedCategory = function (type, group) {
        if (group && $.parseJSON(group).length > 0) {
            group = $.parseJSON(group)[0];
        }
        if (typeof USER_SUBSCRIBED_CATEGORIES != "undefined") {
            if (group) {
                if (typeof USER_SUBSCRIBED_CATEGORIES[group] != "undefined") {
                    return JSON.stringify(USER_SUBSCRIBED_CATEGORIES[group]);
                }
            } else {
                return JSON.stringify(USER_SUBSCRIBED_CATEGORIES);
            }
        }
        var categoryService = CONTROLLER.getService("Category");
        var subscription = this.getUserSubscription();
        if(subscription == "" || subscription == "{}" || subscription == '[]'){
            return "[]";
        }
        if(subscription == ACCESS_DENIED){
            return ACCESS_DENIED;
        }
        var groups = [];
        var collections = [];
        var categories = [];
        var allCategories = $.parseJSON(this.getObjectsOfType(OBJECT_CATEGORY));
        var collectionCategories = [];
        var collectionSkuIds = [];
        var groupCategories = [];
        var subscribedCategory = [];
        var data = USER_SUBSCRIPTIONS;
        for (var key in data) {
            if (data.hasOwnProperty(key) && data[key].status == 1) {
                if (data[key].type == "group") {
                    groups.push(data[key].group_id);
                }
                if (data[key].type == "collection") {
                    collections.push(data[key].collection_id);
                    collectionSkuIds.push(data[key].sku_id)
                }
                if (data[key].type == "category") {
                    categories.push(data[key].category_id);
                }
            }
        }
        if (groups.length > 0) {
            var categoriesInGroup = categoryService.getCategoriesForGroup(groups.join());
            for (var i = 0; i < categoriesInGroup[OBJECT_CATEGORY].length; i++) {
                groupCategories.push(categoriesInGroup[OBJECT_CATEGORY][i].nid);
            }
        }
        if (collections.length > 0 && OBJECT_USER_PRODUCTS.length > 0) {
            for (var j = 0; j < OBJECT_USER_PRODUCTS.length; j++) {
                if (collectionSkuIds.indexOf(OBJECT_USER_PRODUCTS[j].model) > -1) {
                    for (var k = 0; k < OBJECT_USER_PRODUCTS[j][OBJECT_CATEGORY].length; k++)
                        if (collectionCategories.indexOf(OBJECT_USER_PRODUCTS[j][OBJECT_CATEGORY][k]) === -1)
                            collectionCategories.push(OBJECT_USER_PRODUCTS[j][OBJECT_CATEGORY][k]);
                }
            }
        }
        categories.concat(groupCategories, collectionCategories).forEach(function (item) {
            if (subscribedCategory.indexOf(item) == -1) {
                subscribedCategory.push(item);
            }
        });
        var uniqueItems = [];
        if (group) {
            if (typeof USER_SUBSCRIBED_CATEGORIES == "undefined") {
                USER_SUBSCRIBED_CATEGORIES = {};
            }
            USER_SUBSCRIBED_CATEGORIES[group] = [];
        } else {
            USER_SUBSCRIBED_CATEGORIES = [];
        }
        for (var l = 0; l < subscribedCategory.length; l++) {
            var index = findIndexByKeyValue(allCategories[OBJECT_CATEGORY], "nid", subscribedCategory[l]);
            if(index == null){
                index = findIndexByKeyValue(allCategories[OBJECT_CATEGORY], "tid", subscribedCategory[l]);
            }
            if (index != null && $.inArray(allCategories[OBJECT_CATEGORY][index].nid, uniqueItems) == -1) {
                if (group) {
                    if ((isArray(allCategories[OBJECT_CATEGORY][index][OBJECT_GROUP]) && $.inArray(group, allCategories[OBJECT_CATEGORY][index][OBJECT_GROUP]) > -1) ||  allCategories[OBJECT_CATEGORY][index][OBJECT_GROUP].indexOf(group) > -1) {
                        USER_SUBSCRIBED_CATEGORIES[group].push(allCategories[OBJECT_CATEGORY][index]);
                        uniqueItems.push(allCategories[OBJECT_CATEGORY][index].nid);
                    }
                } else {
                    USER_SUBSCRIBED_CATEGORIES.push(allCategories[OBJECT_CATEGORY][index]);
                    uniqueItems.push(allCategories[OBJECT_CATEGORY][index].nid);
                }
            }
        }
        if (group) {
            return JSON.stringify(USER_SUBSCRIBED_CATEGORIES[group]);
        } else {
            return JSON.stringify(USER_SUBSCRIBED_CATEGORIES);
        }
    };

    /***** Video view history Apis *****/

    this.addOrUpdateVideoViewHistory = function(jsonstr, callback,contenttype)
    {
        var mode = getQueryParameter('mode', window.location.href);
        var jsonObj = $.parseJSON(jsonstr),postMessageVideoCompleted=false;
        if((jsonObj.duration > 0 && jsonObj.endtime > 0) || (jsonObj.type == "Document") ||(typeof contenttype != "undefined" && contenttype == "Video")){
            if(jsonObj.duration > 0 && jsonObj.endtime > 0){
                postMessageVideoCompleted = true;
            }
            var jsonarr = [];
            if(jsonObj.hasOwnProperty("lastseentime")){
                jsonObj["lastseenduration"] = Math.floor(jsonObj["lastseentime"]); 
            }
            if(typeof contenttype != "undefined" && contenttype == "Video" && jsonObj["endtime"] == "0"){
                jsonObj["endtime"] = (parseInt(jsonObj.starttime) + 1).toString(); 
                jsonObj["duration"] = "1"; 
            }
            jsonarr[0] = jsonObj;
            var vvhstr = {};
            vvhstr["videoviewed"] = jsonarr;
            vvhstr = JSON.stringify(vvhstr);
            var url = OBJECT_SERVER_DOMAIN+"/api/videoviewhistory?uid="+OBJECT_USER_ID + '&lang='+ Platform.getProperty('lang');

            if(typeof mode !== 'undefined' && mode == 'iframemode'){
                
                var vvhUpdateResponse = this.makeAjaxPostCall(url, vvhstr);
                if(postMessageVideoCompleted == true){
                    window.parent.postMessage("video_watch_completed", "*");
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback();
                }
            }else{
                this.makeAjaxPostCall(url ,vvhstr);
                
                delete_history = function(){
                    if(typeof jsonObj.courseid != 'undefined' && jsonObj.courseid != ''){
                        delete VIDEOS_WATCHED_COURSE[jsonObj.courseid];
                        delete COURSE_PROGRESS[jsonObj.courseid];
                        delete VIDEO_VIEWED_COUNT[jsonObj.courseid];
                        var category = $.parseJSON(Platform.getObjectProperty(OBJECT_CATEGORY,jsonObj.categoryid,"content"));
                        if(typeof category != "undefined" && category != ""){
                            var activetid = category.tid;
                            delete ALL_CATEGORY_PROGRESS[activetid]
                        }
                    } else {
                        VIDEOS_WATCHED_COURSE = {};
                        COURSE_PROGRESS = [];
                        VIDEO_VIEWED_COUNT = {};
                        ALL_CATEGORY_PROGRESS = {};
                    }
                    if (typeof jsonObj.chapterid != 'undefined' && jsonObj.chapterid != '') {
                        delete VIDEOS_WATCHED_CHAPTER[jsonObj.chapterid];
                    } else {
                        VIDEOS_WATCHED_CHAPTER = {};
                    }
                    VIDEOS_WATCHED_CATEGORY = {};
                    SD_VIDEOS_WATCHED_COURSE = {};
                    TOTAL_WATCHED_DURATION = "";
                    if (typeof callback != 'undefined' && typeof callback == 'function') {
                        return callback();
                    }
                }
                delete_history();
            }
        }
    }

    this.updateVVHDuration = function(jsonstr)
    {

        var jsonObj = $.parseJSON(jsonstr);
            var jsonarr = [];
            if(jsonObj.hasOwnProperty("lastseentime")){
                jsonObj["lastseenduration"] = jsonObj["lastseentime"]; 
            }
            jsonarr[0] = jsonObj;
            var vvhstr = {};
            vvhstr["videoviewed"] = jsonarr;
            vvhstr = JSON.stringify(vvhstr);
            var url = OBJECT_SERVER_DOMAIN+ "/api/videoviewhistory/sync"
 
            this.makePostAsyncCall(url,"" ,vvhstr);
        
    }


    this.getVideoViewHistory = function (uid, groupid, objectid, objecttype)
    {
        var result = "";
        var resultjson = {};
        if (VIDEOS_WATCHED_CATEGORY.hasOwnProperty(objectid)) {
            return VIDEOS_WATCHED_CATEGORY[objectid];
        }
        var url = OBJECT_SERVER_DOMAIN + '/api/getwatched' + OBJECT_VIDEO + 'sfor' + OBJECT_CATEGORIES + '?uid=' + OBJECT_USER_ID + '&nid=' + objectid + '&groups=' + groupid  + '&lang='+ Platform.getProperty('lang');
        var result = $.parseJSON(this.makeAjaxPostCall(url));
        if (result["status"] == "200") {
            resultjson["videoviewed"] = $.parseJSON(result["data"]);
            result = JSON.stringify(resultjson);
            VIDEOS_WATCHED_CATEGORY[objectid] = result;
            return result;
        } else {
            resultjson["videoviewed"] = "";
            resultjson = JSON.stringify(resultjson);
            return resultjson;
        }
    };

    /* needs to be checked
     * No Similar API */
    this.getVideoViewHistoryByType = function (uid, groupid, objectid, objecttype, contentType, callback)
    {
        var result = "";
        var resultjson = {};
        if(HIDE_GROUPS_FILTER == "true"){
            groupid = OBJECT_USER_GROUPS.join(", ");
        }
        if (contentType == OBJECT_SUPPORT_DOCS) {
            var courseid = CONTROLLER.getVariable("activecourseid");
            var videos_watched = [];
            if (SD_VIDEOS_WATCHED_COURSE.hasOwnProperty(courseid)) {
                var vvh = SD_VIDEOS_WATCHED_COURSE[courseid];
                vvh = $.parseJSON(vvh);
                if(vvh.hasOwnProperty(objectid)){
                    resultjson["videoviewed"] = vvh[objectid];
                }
                else{
                    resultjson["videoviewed"] = [];
                }
                return JSON.stringify(resultjson);
            } else {
                var url = OBJECT_SERVER_DOMAIN + '/api/getwatched'+OBJECT_SUPPORT_DOCS+'?uid=' + OBJECT_USER_ID + '&nid=' + courseid + '&groups=' + groupid
                + '&lang='+ Platform.getProperty('lang');

                var content = [uid, groupid, objectid, objecttype];

                var json = JSON.stringify(content);

                var result = $.parseJSON(this.makeAjaxPostCall(url));
                if (result["status"] == "200") {
                    resultjson["videoviewed"] = $.parseJSON(result["data"]);
                    SD_VIDEOS_WATCHED_COURSE[courseid] = JSON.stringify(resultjson["videoviewed"]);
                    var vvh = SD_VIDEOS_WATCHED_COURSE[courseid];
                    vvh = $.parseJSON(vvh);
                    if (vvh.hasOwnProperty(objectid)) {
                        resultjson["videoviewed"] = vvh[objectid];
                    }
                    else {
                        resultjson["videoviewed"] = [];
                    }
                    result = JSON.stringify(resultjson);
                    return result;
                } else {
                    resultjson["videoviewed"] = "";
                    resultjson = JSON.stringify(resultjson);
                    return resultjson;
                }
            }
        } else if (objecttype == 'subject') {
            if (VIDEOS_WATCHED_CATEGORY.hasOwnProperty(objectid)) {
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(VIDEOS_WATCHED_CATEGORY[objectid]);
                } else {
                    return VIDEOS_WATCHED_CATEGORY[objectid];
                }
            }
            var url = OBJECT_SERVER_DOMAIN + '/api/getwatched' + OBJECT_VIDEO + 'sfor' + OBJECT_CATEGORIES + '?uid=' + OBJECT_USER_ID + '&nid=' + objectid + '&groups=' + groupid + '&lang='+ Platform.getProperty('lang');
            var result = $.parseJSON(this.makeAjaxPostCall(url));
            if (result["status"] == "200") {
                resultjson["videoviewed"] = sortByTimestamp($.parseJSON(result["data"]));
                result = JSON.stringify(resultjson);
                VIDEOS_WATCHED_CATEGORY[objectid] = result;
            } else {
                resultjson["videoviewed"] = "";
                resultjson = JSON.stringify(resultjson);
                result = resultjson;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        } else if (objecttype == 'course') {
            if (VIDEOS_WATCHED_COURSE.hasOwnProperty(objectid)) {
                if(typeof callback === 'function')
                    return callback(VIDEOS_WATCHED_COURSE[objectid]);
                else
                    return VIDEOS_WATCHED_COURSE[objectid];
            }
            var mode = getQueryParameter('mode', LOCATION_URL);
            if(typeof mode != 'undefined' && mode != null && mode != 'null' && mode == 'adaptivelearning'){
                var url = OBJECT_SERVER_DOMAIN + '/api/getwatched' + OBJECT_VIDEO + 'sfor' + OBJECT_COURSE + '?uid=' + OBJECT_USER_ID + '&nid=' + objectid + '&groups=' + groupid + '&lang='+ Platform.getProperty('lang') + '&category_id='+ CONTROLLER.getVariable('activecatid');
            }else{
                var url = OBJECT_SERVER_DOMAIN + '/api/getwatched' + OBJECT_VIDEO + 'sfor' + OBJECT_COURSE + '?uid=' + OBJECT_USER_ID + '&nid=' + objectid + '&groups=' + groupid + '&lang='+ Platform.getProperty('lang');
            }

            var content = [uid, groupid, objectid, objecttype];

            var json = JSON.stringify(content);

            var temp_vvh_course = function (result) {
                if (result["status"] == "200") {
                    resultjson["videoviewed"] = $.parseJSON(result["data"]);
                    result = JSON.stringify(resultjson);
                    VIDEOS_WATCHED_COURSE[objectid] = result;
                } else {
                    resultjson["videoviewed"] = "";
                    result = JSON.stringify(resultjson);
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return this.makePostAsyncCall(url, temp_vvh_course);
            } else {
                var result = $.parseJSON(this.makeAjaxPostCall(url));
                return temp_vvh_course(result);
            }
        } else if (objecttype == 'chapter') {
            if (VIDEOS_WATCHED_CHAPTER.hasOwnProperty(objectid)) {
                return VIDEOS_WATCHED_CHAPTER[objectid];
            }
            var mode = getQueryParameter('mode', LOCATION_URL);
            if(typeof mode != 'undefined' && mode != null && mode != 'null' && mode == 'adaptivelearning'){
                var url = OBJECT_SERVER_DOMAIN + '/api/getwatched' + OBJECT_VIDEO + 'sfor' + OBJECT_CHAPTER + '?uid=' + OBJECT_USER_ID + '&nid=' + objectid + '&groups=' + groupid + '&lang='+ Platform.getProperty('lang') + '&category_id='+ CONTROLLER.getVariable('activecatid');
            }else{
                var url = OBJECT_SERVER_DOMAIN + '/api/getwatched' + OBJECT_VIDEO + 'sfor' + OBJECT_CHAPTER + '?uid=' + OBJECT_USER_ID + '&nid=' + objectid + '&groups=' + groupid + '&lang='+ Platform.getProperty('lang');
            }

            var content = [uid, groupid, objectid, objecttype];

            var json = JSON.stringify(content);

            var result = $.parseJSON(this.makeAjaxPostCall(url));
            if (result["status"] == "200") {
                resultjson["videoviewed"] = $.parseJSON(result["data"]);
                result = JSON.stringify(resultjson);
                VIDEOS_WATCHED_CHAPTER[objectid] = result;
            } else {
                resultjson["videoviewed"] = "";
                resultjson = JSON.stringify(resultjson);
                result = resultjson;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }

    };

    /* needs to be checked
     * No Similar API */
    this.getVideoViewHistoryCountByType = function (uid, groupid, objectid, objecttype)
    {
          return 0;

    };

    /* needs to be checked
     * No Similar API */
    this.getVideoViewHistoryCountByUserID = function (uid)
    {
        return 0;

    };

    this.getVideoViewHistoryCompletedCountByType = function (uid, groupid, objectid, objecttype, ignoreParams, callback)
    {
        var onGetVVHCompletedCountByTypeComplete = function (vvh) {
            var completedVVH = 0;
            vvh = $.parseJSON(vvh);
            for (var i = 0; i < vvh['videoviewed'].length; i++) {
                if (vvh['videoviewed'][i].status == "completed") {
                    completedVVH = completedVVH + 1;
                }
            }
            if (typeof callback == "function") {
                callback(completedVVH);
            } else {
                return completedVVH;
            }
        }
        if (typeof callback == "function") {
            this.getVideoViewHistoryByType(uid, groupid, objectid, objecttype, "", onGetVVHCompletedCountByTypeComplete);
        } else {
            var vvh = this.getVideoViewHistoryByType(uid, groupid, objectid, objecttype);
            return onGetVVHCompletedCountByTypeComplete(vvh);
        }
    };

    this.deleteVideoViewHistoryCount = function (objectid, objecttype)
    {
        var urldata = "/platformservice/api/platformutil/deleteVideoViewHistoryCount";

        var content = [objectid, objecttype];

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);

    };

    /***** Pending Event Apis *****/

    this.getPendingEvent = function ()
    {
        var urldata = "/platformservice/api/pendingevent/getPendingEvent";

        return this.makeAjaxPostCall(urldata);
    };

    this.removePendingEvent = function (id)
    {
        var urldata = "/platformservice/api/pendingevent/removePendingEvent";

        var content = [id];

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);
    };

    this.removeAllPendingEvents = function ()
    {
        var urldata = "/platformservice/api/pendingevent/removeAllPendingEvents";
         return this.makeAjaxPostCall(urldata);
    };

    /***** Bootstrap Apis *****/
    this.setBootstrapStatus = function (status)
    {

        return "completed";

    };

    this.getBootstrapStatus = function ()
    {

        return "completed";

    };

    this.bootstrapUser = function (jsonstr)
    {

        return JSON.stringify({ 'bootstrap_status': 'success' });

    };

    this.initializeUser = function (jsonstring)
    {

        return true;
    };

    /***** Http Request Apis *****/

    this.makeHttpGetCall = function (url)
    {

        return this.makeAjaxPostCall(url);

    };

    this.makeHttpPostCall = function (url, content, resulttype, methodtype,  ignorecontent)
    {

        return this.makeAjaxPostCall(url,content, resulttype, methodtype, ignorecontent);

    };

    /***** Cron Management Apis *****/

    this.registerCronJob = function (url, frequencyInMins, cronId)
    {
        var content = [url, frequencyInMins, cronId];

        var urldata = "/platformservice/api/platformutil/registerCronJob";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);

    };

    this.getAllCronJobs = function ()
    {
        // var urldata = "/platformservice/api/platformutil/getAllCronJobs";
        //
        // return this.makeAjaxPostCall(urldata);

        return "";

    };

    this.unregisterCronJob = function (id)
    {
        var content = [id];

        var urldata = "/platformservice/api/platformutil/unregisterCronJob";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);

    };

    /* needs to be checked
     * No Similar API */
    this.getDataFromFileSystem = function (filename)
    {
        return "";
    };

    this.getDataFromExternalFileSystem = function (filename)
    {
        var content = [filename];

        var urldata = "/platformservice/api/commonutil/getDataFromExternalFileSystem";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);

    };

    this.saveWebLog = function (message)
    {
        var content = [message];

        var urldata = "/platformservice/api/platformutil/saveWebLog";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);

    };

    this.getWebLog = function ()
    {
        var urldata = "/platformservice/api/platformutil/getWebLog";

        return this.makeAjaxPostCall(urldata);

    };

    this.getServerLog = function ()
    {
        var urldata = "/platformservice/api/platformutil/getServerLog";

        return this.makeAjaxPostCall(urldata);

    };

    this.startService = function ()
    {
        var urldata = "/platformservice/api/platformutil/startService";

        return this.makeAjaxPostCall(urldata);

    };

    this.startSyncService = function ()
    {
        var urldata = "/platformservice/api/platformutil/startSyncService";

        return this.makeAjaxPostCall(urldata);

    };

    this.isFileAddedForDownload = function(nid)
    {
     	var content = [nid];

        var urldata = "/platformservice/api/platformutil/isFileAddedForDownload";

        var json = JSON.stringify(content);

        return this.makeAjaxPostCall(urldata,json);
    };

    this.setProperty = function (key, value) {
        return Platform.setPreference(key, value);
    };

    this.setPreference = function (key, value)
    {
        try{
            if (typeof JSPlatform != "undefined"){
                return JSPlatform.setPreference(key, value);
            }
            else {
                var allPreferences = $.parseJSON(this.getFromLocalStorage('allPreferences'));
                if(!allPreferences) {
                    allPreferences = {};
                }
                allPreferences[key] = value;
                var result = this.saveInLocalStorage('allPreferences', JSON.stringify(allPreferences));
                return;
            }
        }catch(e){
            console.log(e.message);
        }
        return "";
    };

    this.deletePreference = function (param)
    {
        if(typeof JSPlatform != "undefined"){
            JSPlatform.deletePreference(param);
        } else {
            Platform.deleteFromLocalStorage(param);
        }
        return;
    };

    this.getLocalStorageResult = function (quizid, userid)
    {
        var local_result_id = Platform.getFromLocalStorage(quizid+'-'+userid+'-quiz_result');
        if(local_result_id != null){
            var local_result = Platform.getFromLocalStorage('quiz_result-' + userid + '-' + local_result_id);
            if (local_result != null)
                return local_result;
        }
        return false;
    };

    this.getProperty = function (key) {
        return Platform.getPreference(key);
    };

    this.getPreference = function (key)
    {
        try{
            if (typeof JSPlatform != "undefined") {
                return JSPlatform.getPreference(key);
            } else {
                var allPreferences = $.parseJSON(this.getFromLocalStorage('allPreferences'));
                if (allPreferences && allPreferences[key]) {
                    return allPreferences[key];
                } else  {
                    return "";
                }
            }
        }catch(e){
            console.log(e.message);
        }
        return "";
    };

     this.addOrUpdateNotes = function (data)
    {
        var url = OBJECT_SERVER_DOMAIN + "/api/notes/create";
        var postdata = {};
        postdata["content"] = data;
       var returndata = this.makeAjaxPostCall(url,JSON.stringify(postdata));
        var status = returndata['status'];
        if (status == 200) {
            return true;
        } else {
            return false;
        }
    };

     this.getUserNotes = function(type, nid){
         var notifications = {};
        notifications['notification'] = [];
        url = OBJECT_SERVER_DOMAIN + "/api/getnotesofuserby" + type +"?uid="+OBJECT_USER_ID + "&nid=" + nid ;
        var returndata = this.makeAjaxCall(url);
        if(typeof returndata !== 'object'){
             notifications['notification'] = $.parseJSON(returndata);
        }else{
            notifications['notification'] = returndata
        }
        return notifications;
    }


    this.isChapterSubscribed = function(catid, uid,courseid){
        var subscribedchapters = {};
        subscribedchapters[OBJECT_COURSE] = [];
        if(typeof SUBSCRIBED_CHAPTERS[catid] != "undefined" && typeof SUBSCRIBED_CHAPTERS[catid] != ""
            && typeof SUBSCRIBED_CHAPTERS[catid] != null
        ){
            subscribedchapters = SUBSCRIBED_CHAPTERS[catid]
            if(!checkIsEmpty(subscribedchapters)){
                for(var i = 0; i < subscribedchapters.length; i++){
                    if(subscribedchapters[i].nid == courseid){
                        return true
                    }
                }
                return false
            }else{
                 return false
            }
        }else{
            url = OBJECT_SERVER_DOMAIN + "/api/subscribed/" + OBJECT_COURSE + "?uid=" + uid + "&cat_nid=" + catid;
            var returndata = this.makeAjaxCall(url);
            if(typeof returndata !== 'object'){
             subscribedchapters[OBJECT_COURSE] = $.parseJSON(returndata);
            }else{
             subscribedchapters[OBJECT_COURSE] = returndata
            }
            if(!checkIsEmpty(subscribedchapters[OBJECT_COURSE])){
                SUBSCRIBED_CHAPTERS[catid] = subscribedchapters[OBJECT_COURSE]
                 var subscribedchapters = subscribedchapters[OBJECT_COURSE]
                 for(var i = 0; i < subscribedchapters.length; i++){
                     if(subscribedchapters[i].nid == courseid){
                         return true
                     }
                 }
                 return false
             }else{
                  return false
             }
        }
   }

    this.addOrUpdateNotes = function(data, type){
        url = OBJECT_SERVER_DOMAIN + "/api/notes/" + type +"?uid="+OBJECT_USER_ID;
        var returndata = this.makeAjaxPostCall(url,data);
    }
    this.deleteNotes = function(nid){
        url = OBJECT_SERVER_DOMAIN + "/api/notes/delete?uid="+OBJECT_USER_ID + "&nid="+nid;
        var returndata = this.makeAjaxPostCall(url,JSON.stringify(data));
    }

    this.loadWebView = function (path)
    {
        try{
            if (typeof JSPlatform != "undefined")
                JSPlatform.loadView(path);
        }catch(e){}
    };

    this.removeWebView = function ()
    {
        try{
            if (typeof JSPlatform != "undefined")
                JSPlatform.removeView();
        }catch(e){}
    };

    this.loadURL = function (path)
    {
        try{
            if (typeof JSPlatform != "undefined")
                JSPlatform.loadURL(path);
        }catch(e){}
    };

    this.loginToSocialNetwork = function (value)
    {
        try{
            if (typeof JSPlatform != "undefined")
                JSPlatform.loginToSocialNetwork(value);
        }catch(e){}
    };

    this.openBrowser = function(path)
    {
        try{
            if (typeof JSPlatform != "undefined") {
                JSPlatform.openBrowser(path);
            } else {
                window.open(path);
            }
        }catch(e){}
    };

    this.sharePdf = function(path)
    {
        try{
            var path = JSON.parse(path);
            path = path.url;
            if (typeof JSPlatform != "undefined") {
                JSPlatform.openBrowser(path);
            } else {
                window.open(path);
            }
        }catch(e){}
    };

    this.addorUpdateObjectCache = function(objectid,objectvalue){
        try{
           // JSPlatform.AddorUpdateObjectCache(objectid, "",objectvalue);
        }catch(e){}
    };

    this.deleteObjectCache = function(objectid,objecttype){
        try{
            //JSPlatform.DeleteObjectCache(objectid,objecttype);
        }catch(e){}
    };

    this.getObjectCache = function(objectid){
        try{
           return "";
           // return JSPlatform.GetObjectCache(objectid, "");
        }catch(e){ return ""; }
    };


    this.saveInLocalStorage = function(key, value)
    {
        if(typeof JSPlatform != "undefined"){
            value = JSPlatform.setPreference(key, value);
            return;
        }
        if (typeof(Storage) !== "undefined") {
            localStorage.setItem(key, value);
        } else {
            console.log('No support for local storage');
        }
    };
    this.getFromLocalStorage = function(key)
    {
        var value = "";
        if(typeof JSPlatform != "undefined"){
            value = JSPlatform.getPreference(key);
            if(typeof value == "undefined"){
                value = "";
            }
            return value;
        }
        if (typeof(Storage) !== "undefined") {
            var value = localStorage.getItem(key);
            if (value != null) {
                return value;
            }
            return "";
        } else {
            console.log('No support for local storage');
            return '';
        }
    };
    this.deleteFromLocalStorage = function(key)
    {
        if(typeof JSPlatform != "undefined"){
            JSPlatform.deletePreference(key);
            return "";
        }
        if (typeof(Storage) !== "undefined") {
            return localStorage.removeItem(key);
        } else {
            console.log('No support for local storage');
            return '';
        }
    };
    this.clearLocalStorage = function()
    {
        if (typeof(Storage) !== "undefined") {
            return localStorage.removeItem(key);
        } else {
            console.log('No support for local storage');
            return '';
        }
    };
    this.getObjectByID = function (key) {
        if (key.indexOf('temp_coachmark_') > -1) {
            if (typeof ALL_COACH_MARK_DATA != "undefined" && ALL_COACH_MARK_DATA.length > 0) {
                return ALL_COACH_MARK_DATA;
            }
            var url = OBJECT_SERVER_DOMAIN + '/api/get/coachmark?auth=' + AUTH_ENABLED + '&uid=' + OBJECT_USER_ID;
            var result = $.parseJSON(this.makeAjaxPostCall(url));
            if (result["status"] == "200") {
                ALL_COACH_MARK_DATA = result["data"];
                return result["data"];
            } else {
                return '';
            }
        }
        return "";
    };
    this.getEncryptedObjectProperty = function (objectType, objectId) {
        return this.getObjectProperty(objectType, objectId);
    }
    this.uploadVideoViewHistory = function () {
        return;
    }
    this.markQuizAsAttempted = function () {
        return;
    }
    this.getAllLanguages = function () {
        if (typeof ALL_LANGUAGES != "undefined" && ALL_LANGUAGES.length > 0) {
            return ALL_LANGUAGES;
        }
        var url = OBJECT_SERVER_DOMAIN + "/api/getalllanguages?uid="+OBJECT_USER_ID;
        var response = this.makeAjaxCall(url);
        ALL_LANGUAGES = response;
        return response;
    }
    this.getQRCAuthUrl = function (url) {
        var expiry = 24 * 60 * 60 * 1000; //1day
        var time = getSyncedServerTime() + expiry;
        try{
            var map = extractParamAndDecode(url);

            if(map == null || !map.hasOwnProperty("aesl_id")){
                console.log("getQRCAuthUrl - Unable to extract params from url");
                return url;
            }

            var token = "student=" + map["aesl_id"] + "&time=" + time;

            if (typeof CryptoJS != "undefined") {
                var hash = CryptoJS.HmacSHA256(token, "secret");
                var hashInHex = CryptoJS.enc.Hex.stringify(hash);
            } else {
                var hash = token;
            }

            return url + "&time=" + time + "&authentication_token=" +  hashInHex;
        }catch (e){
            console.log(e)
        }
        return url;
    }
    this.share = function (title, body) {
        if (navigator.share) {
            navigator.share({
                text: body
            });
        } else {
            console.log('Error sharing - navigator.share is not available');
        }
    };
    this.toggleScreenCapture = function () {
        return;
    };

    this.addorUpdateCache = function (data) {
        if(typeof ALL_CACHE == "undefined"){
            ALL_CACHE = {};
        }
        data = JSON.parse(data)
        ALL_CACHE[data.cacheid] = data.cachedata
    };

    this.getCachedetails = function (key) {
        if(typeof ALL_CACHE == "undefined"){
            ALL_CACHE = {};
        }
        if(typeof ALL_CACHE[key] != "undefined" &&  ALL_CACHE[key] != ""){
            return ALL_CACHE[key];
        }else{
            return "";
        }
    };


    this.addPdfViewHistory = function (data) {
        try{
            var urldata = OBJECT_SERVER_DOMAIN + "/api/update/document/viewcount?auth=" + AUTH_ENABLED;
            data = JSON.parse(data);
            var pageInfo =  {
                "page_number": data.page_number,
                "action": data.action,
                "mode": data.mode,
                "start_time": data.start_time,
                "end_time": data.end_time,      
            }
            var content = {
                "doc_id": data.doc_id,
                "cat_id": data.categoryid,
                "uid": data.uid,
                "course_id": data.course_id,
                "chapter_id": data.chapter_id,
                "pages" : []
            }
            content['pages'].push(pageInfo);
            var json = JSON.stringify(content);
            return this.makeAjaxPostCall(urldata,json);
        }catch(e){
            console.log("addPdfViewHistory " + e.message);
        }
    };

    this.getNextLearningMaterial = function (OBJECT_USER_ID,groupid,catid,course_id) {
        try {
            var url = OBJECT_SERVER_DOMAIN + "/api/getnextcontent?uid=" + OBJECT_USER_ID + "&groups=" + groupid + "&course_nid=" + catid + "&topic_nid=" + course_id;
            var response = this.makeAjaxCall(url);
            return JSON.stringify(response);
        } catch (e) {
            console.log("getNextLearningMaterial " + e.message);
        }
    }

    this.setDeviceOrientation = function (params) {
        try {
            if($(window).width() > $(window).height() && document.fullscreenElement && typeof document.exitFullscreen != 'undefined' && params == "portrait"){
                if(checkUserAgent() == 'android') {
                    screen.orientation.lock('portrait')
                    .then(() => {
                        setTimeout(() => {
                            document.exitFullscreen();
                        }, 100);
                    })
                    .catch((error) => {
                        console.error('Failed to lock the screen orientation portrait:', error);
                    });
                }
            }
        } catch (e) {
            console.log("setDeviceOrientation " + e.message);
        }
    }

    this.BuildNotification = function(){
        return;
    }
    this.cancelNotification = function(){
        return;
    }
    this.isAirplaneModeEnabled = function(){
        return;
    }
    this.closeSkeletonGIF = function(){
        return;
    }
    this.showImageDialog = function(){
        return;
    }
}

var Platform = new Platform();


function Client()
{
    //Object Interface

    this.makeClientAjaxCall = function (urldata) {
        var result = "";
        $.ajax({
            type: "GET",
            url: urldata,
            context: document.body,
            async: false,
            success: function (data) {
                // Run the code here that needs
                // to access the data returned
                result = data;
            },
            error: function () {
                this.removeDialog();
                this.closeProgressBar();
                alert('Cannot connect to server');
            }
        });
        return result;
    };

    this.showMessage = function(message)
    {
        //var urldata = "/clientservice/api/showMessage?param0=" + message;
       // return this.makeClientAjaxCall(urldata);

       return "";
    };


    this.showDialog = function(message)
    {
        try{
            //JSPlatform.showDialog();
        }catch(e){};
    };


    this.removeDialog = function()
    {
        try{
            if (typeof JSPlatform != "undefined") {
                JSPlatform.removeDialog();
            }
        }catch(e){};
    };

    this.closeDialog = function()
    {
        //var urldata = "/clientservice/api/removeDialog";

       // return this.makeClientAjaxCall(urldata);

       return "";

    };

    this.trackScreen = function()
    {

       return "";

    };

    this.showSystemSettings = function()
    {
       // var urldata = "/clientservice/api/showSystemSettings?param0=";

       // return this.makeClientAjaxCall(urldata);

       return "";
    };

    this.showProgressBar = function (message) {
        try {
            if (typeof message == "undefined" || message == "") {
                message = "Loading...";
            }
	        if(typeof IS_APP !='undefined' && IS_APP !='' && IS_APP == "true" && typeof JSPlatform != "undefined"){
            try{
                JSPlatform.showProgressBar(message);
            }catch(e){}
        } else {
            var loadingBgColor = '#0080C6';
            if (typeof LOADING_BG_COLOR != "undefined" && LOADING_BG_COLOR != "") {
                loadingBgColor = LOADING_BG_COLOR;
            }
            $.blockUI({ css: { backgroundColor: loadingBgColor, color: '#fff',width: "180px",
                        "font-size": "17px", padding: "5px", left: "50%", top: "50%", "font-family": "WorkSans-Bold",
                        "z-index" : 1000000, border: "1px solid rgb(170, 170, 170)",transform: 'translate(-50%,-50%)', 'opacity': '1'},message: message,  });
            	$(".blockOverlay").css("z-index","1000000");
        	}
	    }
        catch (e) {
            console.log(e.message + " function = showProgressBar");
        }
    }

    this.closeProgressBar = function () {
        try {
            if (typeof IS_APP != 'undefined' && IS_APP != '' && IS_APP == "true" && typeof JSPlatform != "undefined") {
                try {
                    JSPlatform.closeProgressBar();
                } catch (e) { }
            } else {
                $.unblockUI();
            }
        }
        catch (e) {
            console.log(e.message + " function = closeProgressBar");
        }
    };

    this.launchQRReader = function () {
        if (typeof JSPlatform != "undefined") {
            JSPlatform.launchQRReader();
        } else {
            alert("QR Reader is not supported");
        }
    };

    this.openUrlInWebview = function (params){
        var url
        if (typeof JSPlatform != "undefined"){
            JSPlatform.openUrlInWebview(params);
        } else if (APP_TYPE == "web") {
            if (typeof params === "string") {
                try {
                    params = JSON.parse(params); 
                    url = params.url; 
                } catch (error) {
                    console.error("Invalid JSON string:", error);
                    url = params; 
                }
            } else {
                url = params.url || params; 
            }
            window.open(url);
        }
    }

    this.launchWebinar = function (nid,password,username) {
        if(typeof APP_TYPE != 'undefined' && APP_TYPE !='' && APP_TYPE == "ios" && typeof JSPlatform != "undefined"){
            try{
                var zoomurl = OBJECT_SERVER_DOMAIN + '/api/get/webinarurl?nid=' + nid + "&uid=" + OBJECT_USER_ID;
                zoomurl = Platform.getAuthenticatedURL(zoomurl, 'GET', 'cloud');
                zoomurl = Platform.makeHttpGetCall(zoomurl);
                response = $.parseJSON(zoomurl);
                var status = response['status'];
                if (status == 200) {
                    var url = $.parseJSON(response['data']);
                    Platform.openBrowser(url["join_url"]);
                }       
            }catch(e){
                console.log(e.message);
            }
             
        }
        else{
	        var group = "";
	        if (HIDE_GROUPS_FILTER != "true") {
	            group = "&active_group=" + CONTROLLER.getVariable('activegroupid');
	        }
	        var meetingUrl = OBJECT_SERVER_DOMAIN + '/join/' + OBJECT_WEBINAR + '?nid=' + nid + '&dialog=true' + group;
	        if (typeof JSPlatform != "undefined") {
	            var redirectPath = OBJECT_SERVER_DOMAIN + '/api/login/redirect?uid=' + OBJECT_USER_ID + "&useractive=true&path=" + encodeURIComponent(meetingUrl);
	                redirectPath = Platform.getAuthenticatedURL(redirectPath, "GET");
	        } else {
	            var redirectPath = meetingUrl;
	        }
	        Platform.openBrowser(redirectPath);
        }
    };

    this.startWebinar = function (nid, password, username) {
        if(typeof APP_TYPE != 'undefined' && APP_TYPE !='' && APP_TYPE == "ios" && typeof JSPlatform != "undefined"){
            JSPlatform.startWebinar(nid,password,username);
        }
        else{
	        var group = "";
	        if (HIDE_GROUPS_FILTER != "true") {
	            group = "&active_group=" + CONTROLLER.getVariable('activegroupid');
	        }
	        var meetingUrl = OBJECT_SERVER_DOMAIN + '/join/' + OBJECT_WEBINAR + '?session_action=start&nid=' + nid + '&dialog=true' + group;
	        if (typeof JSPlatform != "undefined") {
	            var redirectPath = OBJECT_SERVER_DOMAIN + '/api/login/redirect?uid=' + OBJECT_USER_ID + "&useractive=true&path=" + encodeURIComponent(meetingUrl);
	                redirectPath = Platform.getAuthenticatedURL(redirectPath, "GET");
	        } else {
	            var redirectPath = meetingUrl;
	        }
	        Platform.openBrowser(redirectPath);
        }
    };

    this.googleSignIn = function(){
        try{
            var url = OBJECT_SERVER_DOMAIN + '/api/googleauthurl';
            var signin_url = Platform.makeHttpGetCall(url);
            var response = $.parseJSON(signin_url);
            if(typeof response != 'undefined' && typeof response['status'] != 'undefined' && response['status'] == 200){
                var googleauthurl = $.parseJSON(response['data']);
                googleauthurl = googleauthurl['data'].auth_url;
                window.location.href = googleauthurl;
            }else{
                alert(t("ERROR_MESSAGE", true));
                Client.closeProgressBar();
                return false;
            }
        }catch(e){
            console.log("function googleSignIn" + e)
        }
    }

    this.googleSignOut = function(){
        return;
    }
}

var Client = new Client();
